import React, { useContext } from 'react';
import Select from 'react-select';
import { FaTrashAlt } from 'react-icons/fa';
import { SubscriptionContext } from '../../contexts/SubscriptionContext';

const SocialMediaLinksComponent = ({
                                       socialMediaLinks,
                                       handleAddSocialMediaLink,
                                       handleSocialMediaLinkChange,
                                       handleRemoveSocialMediaLink,
                                   }) => {
    const { selectedPlan } = useContext(SubscriptionContext);
    const { productType } = selectedPlan;

    const colors = {
        background: productType === 'PRODUCT' ? '#f49ac1' : '#C5E09D',
        border: productType === 'PRODUCT' ? '#B22a7a' : '#1e3f16',
        text: productType === 'PRODUCT' ? '#B23278' : '#253329',
    };

    const platformOptions = [
        { value: 'TWITTER', label: 'Twitter' },
        { value: 'FACEBOOK', label: 'Facebook' },
        { value: 'INSTAGRAM', label: 'Instagram' },
        { value: 'LINKEDIN', label: 'LinkedIn' },
        { value: 'YOUTUBE', label: 'YouTube' },
    ];

    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: colors.background,
            borderColor: state.isFocused ? colors.border : colors.text,
            boxShadow: state.isFocused ? `0 0 0 1px ${colors.border}` : null,
            '&:hover': { borderColor: colors.border },
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? colors.border : state.isFocused ? colors.background : '#ffffff',
            color: state.isSelected ? '#ffffff' : colors.text,
            '&:hover': { backgroundColor: colors.background, color: colors.text },
        }),
        placeholder: (base) => ({ ...base, color: colors.text, fontFamily: 'Poppins, sans-serif' }),
        singleValue: (base) => ({ ...base, color: colors.text }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };

    return (
        <div className="p-6 rounded-lg shadow-md max-w-3xl mx-auto font-poppins" style={{ backgroundColor: colors.background }}>
            <h2 className="text-2xl font-bold mb-6 text-center" style={{ color: colors.text }}>Social Media Links</h2>
            {socialMediaLinks.map((link, index) => (
                <div
                    key={index}
                    className="mb-4 p-4 bg-white rounded-lg shadow-sm flex flex-col space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4"
                    style={{ borderColor: colors.border }}
                >
                    <div className="w-full md:w-1/3">
                        <Select
                            options={platformOptions}
                            value={platformOptions.find(option => option.value === link.platform) || "Select platform"}
                            onChange={(selectedOption) => handleSocialMediaLinkChange(index, 'platform', selectedOption.value)}
                            placeholder="Select Platform"
                            className="w-full"
                            styles={customStyles}
                            menuPortalTarget={document.body}
                            menuPosition="fixed"
                        />
                    </div>

                    <div className="w-full md:w-2/3">
                        <input
                            type="text"
                            value={link.url}
                            onChange={(e) => handleSocialMediaLinkChange(index, 'url', e.target.value)}
                            placeholder="Enter social media URL"
                            required
                            className="border p-3 rounded-md w-full bg-gray-100"
                        />
                    </div>

                    <button
                        className="flex items-center space-x-2 mt-4 md:mt-0"
                        onClick={() => handleRemoveSocialMediaLink(index)}
                        style={{ color: colors.text }}
                    >
                        <FaTrashAlt className="mr-2" size={16} />
                        <span>Remove</span>
                    </button>
                </div>
            ))}

            <div className="text-center mt-6">
                <button className="custom-btn-primary" onClick={() => handleAddSocialMediaLink({ platform: 'FACEBOOK', url: '' })}>
                    Add Social Media Link
                </button>
            </div>
        </div>
    );
};

export default SocialMediaLinksComponent;
