import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom'; // To capture query params
import axiosAuth from "../../custom-axios/axios-auth";
import { UserContext } from '../../contexts/UserContext';
const ConfirmAccount = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams(); // Capture query parameters
    const token = searchParams.get('token');
    const { setIsEnabled, setVerificationMessage } = useContext(UserContext);

    const navigate = useNavigate(); // Initialize useNavigate hook
    useEffect(() => {
        const confirmAccount = async () => {
            if (!token) {
                setMessage('Token is missing');
                setLoading(false);
                return;
            }
            try {
                const response = await axiosAuth.get(`/confirm-account?token=${token}`);
                if(response.status===200){
                    const newToken = response.data;
                    localStorage.setItem('JWT', newToken);
                    setMessage("Account confirmed successfully.");
                    setIsEnabled(true);
                    setVerificationMessage(null);
                    await new Promise(resolve => setTimeout(resolve, 3000));
                }
                else if(response.status===202) {
                    setMessage("The token is expired.");
                }
                else if(response.status===204) {
                    setMessage("The account is verified.");
                }
            } catch (error) {
                setMessage('Failed to confirm account. The token may be invalid or the user is not logged in.');
            } finally {
                setLoading(false);
            }
        };

        confirmAccount(); // Call the function on component mount
    }, [token]);

    const handleResendToken = async () => {
        try {
            const response = await axiosAuth.post('/resend-confirmation-token');
            if (response.status === 200) {
                setMessage("A new confirmation token has been sent to your email.");
            }
        } catch (error) {
            setMessage("Failed to resend the confirmation token. Please try again later.");
        }
    };

    if (loading) {
        return <p>Loading...</p>; // Show loading state while waiting for the response
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Account Confirmation</h2>
            <p className="text-xl text-gray-600">{message}</p>
            {message === "The token is expired." && (
                <button
                    onClick={handleResendToken}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Resend Confirmation Token
                </button>
            )}
        </div>
    );
};

export default ConfirmAccount;
