import React, {useEffect, useState} from 'react';
import authenticatedInstance from "../../custom-axios/axios-auth";
import {Link} from 'react-router-dom';
import DeleteUserButton from "./DeleteUserButton";

const SettingsPage = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showCustomerDetails, setShowCustomerDetails] = useState(true);
    const [showSellerDetails, setShowSellerDetails] = useState(true);
    const [showContactInfo, setShowContactInfo] = useState(false);
    const [showSocialMediaLinks, setShowSocialMediaLinks] = useState(false);
    const [showShopDetails, setShowShopDetails] = useState(false);
    const [showCategories, setShowCategories] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await authenticatedInstance.get('/users/settings');
                if (response.status === 200) {
                    setUserDetails(response.data);
                } else {
                    console.error('Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserDetails();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!userDetails) {
        return <div>User details not available</div>;
    }

    return (
        <div className="max-w-5xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-4xl font-semibold mb-8 text-center">User Settings</h2>

            {/* Customer Information */}
            {userDetails.customerProfile && (
                <div className="mb-6 border rounded-lg shadow-sm p-4 bg-gray-100">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-2xl font-semibold">Personal Information</h3>
                        <button
                            onClick={() => setShowCustomerDetails(!showCustomerDetails)}
                            className="text-blue-500 focus:outline-none"
                        >
                            {showCustomerDetails ? 'Hide' : 'Show'}
                        </button>
                    </div>
                    {showCustomerDetails && (
                        <table className="min-w-full table-fixed">
                            <tbody>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">First Name:</td>
                                <td className="px-4 py-2">{userDetails.customerProfile.firstName}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Last Name:</td>
                                <td className="px-4 py-2">{userDetails.customerProfile.lastName}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Email:</td>
                                <td className="px-4 py-2">{userDetails.email}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Orders:</td>
                                <td className="px-4 py-2">
                                    <Link to="/customer/orders" className="text-blue-500">View Orders</Link>
                                </td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 underline text-blue-500">
                                    <Link to="/settings/changepassword" className="text-blue-500">Change Password</Link>
                                </td>
                                <td className="px-4 py-2 underline text-blue-500">
                                    <Link to="/settings/changeemail" className="text-blue-500">Change Email</Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            )}

            {/* Seller Information */}
            {userDetails.sellerProfile && (
                <div className="mb-6 border rounded-lg shadow-sm p-4 bg-gray-100">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-2xl font-semibold">Seller Information</h3>
                        <button
                            onClick={() => setShowSellerDetails(!showSellerDetails)}
                            className="text-blue-500 focus:outline-none"
                        >
                            {showSellerDetails ? 'Hide' : 'Show'}
                        </button>
                    </div>
                    {showSellerDetails && (
                        <table className="min-w-full table-fixed">
                            <tbody>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">First Name:</td>
                                <td className="px-4 py-2">{userDetails.sellerProfile.firstName}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Last Name:</td>
                                <td className="px-4 py-2">{userDetails.sellerProfile.lastName}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Orders:</td>
                                <td className="px-4 py-2">
                                    <Link to="/seller/orders" className="text-blue-500">View Orders</Link>
                                </td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Approved:</td>
                                <td className="px-4 py-2">{userDetails.sellerProfile.approved ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">Bio:</td>
                                <td className="px-4 py-2">
                                    {userDetails.sellerProfile.bio?.substring(0, 100)}
                                    {userDetails.sellerProfile.bio?.length > 100 && (
                                        <button
                                            className="text-blue-500 focus:outline-none ml-2"
                                            onClick={() => setShowSellerDetails(!showSellerDetails)}
                                        >
                                            Show more
                                        </button>
                                    )}
                                </td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">
                                    <button
                                        onClick={() => setShowContactInfo(!showContactInfo)}
                                        className="text-blue-500 focus:outline-none"
                                    >
                                        Contact Information
                                    </button>
                                </td>
                                <td className="px-4 py-2">
                                    {showContactInfo && (
                                        <div className="ml-4">
                                            <div>Email: {userDetails.sellerProfile.contactInformation?.email}</div>
                                            <div>Phone: {userDetails.sellerProfile.contactInformation?.mobilePhone}</div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">
                                    <button
                                        onClick={() => setShowSocialMediaLinks(!showSocialMediaLinks)}
                                        className="text-blue-500 focus:outline-none"
                                    >
                                        Social Media Links
                                    </button>
                                </td>
                                <td className="px-4 py-2">
                                    {showSocialMediaLinks && userDetails.sellerProfile.socialMediaLinks?.length > 0 && (
                                        <ul className="ml-4">
                                            {userDetails.sellerProfile.socialMediaLinks.map((link, index) => (
                                                <li key={index}>
                                                    {link.platform}: <a href={link.url}
                                                                        className="text-blue-500">{link.url}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </td>
                            </tr>
                            <tr className="border-b">
                                <td className="px-4 py-2 font-semibold">
                                    <button
                                        onClick={() => setShowShopDetails(!showShopDetails)}
                                        className="text-blue-500 focus:outline-none"
                                    >
                                        Shop Information
                                    </button>
                                </td>
                                <td className="px-4 py-2">
                                    {showShopDetails && (
                                        <div className="ml-4 mt-4 p-4 border rounded-lg bg-white shadow-sm">
                                            <div>Shop Name: {userDetails.sellerProfile.shop?.name}</div>
                                            <div>Description: {userDetails.sellerProfile.shop?.description}</div>
                                            <div>Zip Code: {userDetails.sellerProfile.shop?.zipCode}</div>
                                            <div>Average Rating: {userDetails.sellerProfile.shop?.averageRating}</div>
                                            <button
                                                onClick={() => setShowCategories(!showCategories)}
                                                className="text-blue-500 focus:outline-none mt-2"
                                            >
                                                Categories
                                            </button>
                                            {showCategories && (
                                                <ul className="ml-4 mt-2">
                                                    {userDetails.sellerProfile.shop?.categoryFilters.map((category, index) => (
                                                        <li key={index}>{category.name}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            <button
                                                onClick={() => setShowProducts(!showProducts)}
                                                className="text-blue-500 focus:outline-none mt-2"
                                            >
                                                Products
                                            </button>
                                            {showProducts && (
                                                <ul className="ml-4 mt-2">
                                                    {userDetails.sellerProfile.shop?.products.map((product, index) => (
                                                        <li key={index} className="mt-2">
                                                            <span>{product.title}</span>
                                                            <Link
                                                                to={`/product/${product.id}`}
                                                                className="text-blue-500 ml-2"
                                                            >
                                                                View Product
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    )}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            )}
            {/* Add Delete User Button at the bottom */}
            <div className="mt-8">
                <DeleteUserButton
                    userId={userDetails.id} // Assuming the user ID is in userDetails
                    onUserDeleted={() => {
                        alert("User deleted successfully.");
                    }}
                />
            </div>
        </div>
    );
};

export default SettingsPage;