import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import Autocomplete from "../Search/SearchAutocomplete";
import HomePageCards from './HomePageCards';
import useSearch from "../../hooks/useSearch";
import { productTitles, serviceTitles } from './titles';
import { useSearchContext } from "../../contexts/SearchContext";
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { productImageMapping, serviceImageMapping } from './imageMappings';
import axios from "../../custom-axios/axios";
import {useNavigate} from "react-router-dom";
import authenticatedInstance from "../../custom-axios/axios-auth";
import HolidayModal from "./HolidayModal";  // Adjust the path as necessary

const MobileHomePage = () => {
    const { selectedType, setSelectedType, zipCode, setZipCode } = useSearchContext();
    const { handleSearch } = useSearch(zipCode, selectedType);
    const cardTitles = selectedType === 'product' ? productTitles : serviceTitles;
    const navigate = useNavigate();
    const [error, setError] = useState(null);  // Error state for invalid ZIP codes
    const [isZipValid, setIsZipValid] = useState(false);

    // Dynamically update CSS root variables based on selected product/service

    useThemeSwitcher(selectedType);
    const handleClick = async (category) => {
        try {
            const response = await axios.get(`/home/search/category?category=${category}&type=${selectedType}`);
            setError(null);  // Clear any errors if request succeeds
            navigate(`/results`, {state: {shops: response.data, type: selectedType}});
        } catch (err) {
            setError('No shops found for this ZIP code.');
        }
    };
    // Slider settings for mobile-friendly scrolling of cards
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoriesHolidays, setCategoriesHolidays] = useState([]);
    useEffect(() => {
        const modalClosed = localStorage.getItem("modalClosed");

        // If the modal has been closed, we don't want to show it again
        if (!modalClosed) {
            fetchCategories();
        }
    }, []);

    useEffect(() => {
        // Only open the modal if the categories are fetched and the length is greater than 0
        if (categoriesHolidays.length > 0 && !localStorage.getItem("modalClosed")) {
            setIsModalOpen(true);
        }
    }, [categoriesHolidays]);
    // Function to close the modal and save to localStorage
    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem("modalClosed", "true"); // Mark the modal as closed in localStorage
    };
    const handleZipChange = (zip) => {
        setZipCode(zip); // Update zip code in context
        const isValid = /^\d{2,5}$/.test(zip) || zip.length === 0; // Validate zip code (2-5 digits)
        setIsZipValid(isValid); // Set validity state
    };

    useEffect(() => {
        handleZipChange(zipCode); // Validate on component mount
    }, [zipCode]);
    const fetchCategories = async () => {
        try {
            // Fetch holidays (same regardless of product type)
            const holidaysResponse = await authenticatedInstance.get('/categories/holidays');
            const holidays = holidaysResponse.data;

            // Get today's date
            const today = new Date();

            // Filter holidays that are within 30 days from today
            const upcomingHolidays = holidays.filter((holiday) => {
                // Parse the date
                const holidayDate = new Date(holiday.date);
                // Calculate the difference in time and convert it to days
                const timeDifference = holidayDate.getTime() - today.getTime();
                const daysDifference = timeDifference / (1000 * 3600 * 24);

                // Return true if the holiday is within 30 days
                return daysDifference >= 0 && daysDifference <= 30;
            });

            // Extract unique category names and map them into the format { label, value }
            const uniqueCategoryHolidays = [
                ...new Map(upcomingHolidays.map((holiday) => [holiday.id, holiday])).values()
            ];
            // Update state
            setCategoriesHolidays(uniqueCategoryHolidays);

        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to load categories.');
        }
    };
    return (
        <div className="min-h-screen flex flex-col justify-between"
             style={{
                 backgroundColor: 'var(--circle-bg-color)',
             }}>
            {/* Main Content */}
            <div className="flex-grow">
                {/* SVG Logo without Curve */}
                <div className="text-center mt-8 z-index-10">
                    <h1 className="text-3xl font-rageItalic font-bold"
                        style={{color: 'var(--svg-text-color)'}}>TheHomeMadeShop.com</h1>
                    <h2 className="text-2xl font-light" style={{color: 'var(--h2-color)'}}>Made with love</h2>
                    <h3 className="text-lg mb-6" style={{color: 'var(--h3-color)'}}>Use your neighbour's hands - Free of
                        rush and drama</h3>

                    {/* ZIP Code Search */}
                    <div className="flex justify-center mb-8">
                        <div className="flex items-center space-x-4">
                            <Autocomplete zipCode={zipCode} setZip={setZipCode}/>
                            <button
                                onClick={handleSearch}
                                className={`px-4 py-2 rounded-md ${!isZipValid && zipCode !== '' ? 'cursor-not-allowed opacity-50' : ''}`}  // Apply styles when disabled
                                style={{
                                    backgroundColor: 'var(--search-btn-color)',
                                    color: 'var(--search-btn-text)',
                                    border: '2px solid var(--circle-border-color)',
                                    whiteSpace: 'nowrap',
                                }}
                                disabled={!isZipValid && zipCode !== ''}  // Disable button if ZIP code is invalid and not empty
                                title={
                                    !isZipValid && zipCode !== '' ?
                                        'Enter a valid 2-5 digit ZIP code' :
                                        (zipCode === '' ? 'Leave blank to search for all products/services' : '')
                                }  // Tooltip based on ZIP code status
                            >
                                {selectedType === 'product' ? 'Find Shops' : 'Find Services'}
                            </button>


                        </div>
                    </div>
                    {error && <p className="text-customRed mt-4">{error}</p>}
                </div>

                {/* Product/Service Type Buttons */}
                <div className="buttons flex gap-8 justify-center mb-6">
                    <button
                        className="px-6 py-4 text-lg"
                        style={{
                            backgroundColor: 'var(--products-btn-color)',
                            color: 'var(--products-btn-text)',
                            border: '2px solid #B23278',
                            padding: '1rem 2rem', // Increased padding for larger button
                        }}
                        onClick={() => setSelectedType('product')}  // Update the selected type in context
                    >
                        Products
                    </button>
                    <button
                        className="px-6 py-4 text-lg"
                        style={{
                            backgroundColor: 'var(--services-btn-color)',
                            color: 'var(--services-btn-text)',
                            border: '2px solid #253329',
                            padding: '1rem 2rem', // Increased padding for larger button
                        }}
                        onClick={() => setSelectedType('service')}  // Update the selected type in context
                    >
                        Services
                    </button>
                </div>
                <p className="text-center text-sm" style={{color: 'var(--p-text-color)'}}>
                    Unique and high-quality products and services in TheHomeMadeShop.com next door
                </p>

                {/* Cards Carousel */}
                <div className="mt-8 px-3">
                    <div className="flex flex-wrap justify-center">
                        {cardTitles.map((title, index) => (
                            <div key={index} className="flex-none w-1/3 px-1 mb-1"> {/* Ensure 3 items per row */}
                                <div
                                    className="flex flex-col justify-center items-center bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 pt-1"
                                    style={{
                                        backgroundColor: selectedType === 'product' ? 'var(--secondary-product-color)' : 'var(--secondary-service-color)',
                                    }}
                                    onClick={() => handleClick(title)}
                                >
                                    <h3
                                        className="homePage-card-title text-xl font-bold mb-2 text-center truncate"
                                        style={{
                                            color: selectedType === 'product' ? 'var(--primary-product-color)' : 'var(--primary-service-color)',
                                        }}
                                    >
                                        {title}
                                    </h3>
                                    {selectedType === 'product' && productImageMapping[title] ? (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/home/${productImageMapping[title]}`}
                                            alt={title}
                                            className="w-full h-32 object-contain rounded-lg shadow-md"
                                        />
                                    ) : selectedType === 'service' && serviceImageMapping[title] ? (
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/home/${serviceImageMapping[title]}`}
                                            alt={title}
                                            className="w-full h-32 object-contain rounded-lg shadow-md"
                                        />
                                    ) : (
                                        <div className="homePage-card-image-placeholder mb-2">No image available</div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


            </div>
            <HolidayModal isOpen={isModalOpen} onClose={closeModal} categories={categoriesHolidays}/>

            {/* Footer Section */}
            <div className="footer mt-6">

            </div>
        </div>
    );
};

export default MobileHomePage;
