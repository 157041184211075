import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "../custom-axios/axios";

const useSearch = (zip, selected) => {
    const [error, setError] = useState(null);  // Error state for invalid ZIP codes
    const navigate = useNavigate();

    const handleSearch = async () => {
        // Check if the zip code is valid (4 or 5 digits)
        if (zip && !/^\d{4,5}$/.test(zip)) {
            setError('Please enter a valid 4 or 5-digit ZIP code.');
            return;
        }

        try {
            // Perform the search API call
            const response = await axios.get(`/home/search?zip=${zip}&type=${selected}`);
            setError(null);  // Clear any errors if request succeeds

            // Navigate to the results page with the fetched data
            navigate('/results', { state: { shops: response.data, zipCode: zip, type: selected } });
        } catch (err) {
            // If the API call fails, show a "No shops found" error
            setError('No shops found for this ZIP code.');
        }
    };

    return { handleSearch, error };
};

export default useSearch;