import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from "react-modal";
import { FaTrash } from "react-icons/fa"; // Example icon from react-icons
import authenticatedInstance from "../../custom-axios/axios-auth";
import { UserContext} from "../../contexts/UserContext";

Modal.setAppElement("#root"); // Set the app element for accessibility

const DeleteUserButton = ({ userId, onUserDeleted }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { logout } = useContext(UserContext); // Access the logout function from context
    const navigate = useNavigate();

    // Open the modal
    const openModal = () => setIsModalOpen(true);

    // Close the modal
    const closeModal = () => setIsModalOpen(false);

    // Handle delete confirmation
    const handleDelete = async () => {
        setIsDeleting(true);
        setErrorMessage("");

        try {
            const response = await authenticatedInstance.post("/auth/deleteUser");

            if (response.status === 200) {
                alert("Your account has been deleted.");
                closeModal();

                // Log out the user
                logout();

                // Redirect to the home page
                navigate("/", { replace: true });
            } else {
                setErrorMessage("Failed to delete user. Please try again.");
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "An unexpected error occurred.");
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div>
            {/* Delete Button */}
            <button
                onClick={openModal}
                className="flex items-center gap-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
            >
                <FaTrash className="text-lg" />
                Delete User
            </button>

            {/* Confirmation Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Delete Confirmation"
                className="bg-white rounded-lg shadow-lg p-6 max-w-lg mx-auto outline-none"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
                <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
                <p className="text-gray-700 mb-4">
                    Are you sure you want to permanently delete your account? This action cannot be undone and will
                    remove all data associated with your account, including:
                </p>
                <ul className="list-disc pl-5 text-gray-600 mb-4">
                    <li>All user profiles (Seller/Customer)</li>
                    <li>All orders and shop information</li>
                    <li>All conversations</li>
                </ul>
                <p className="text-red-600 font-semibold">This action is irreversible.</p>

                {/* Error Message */}
                {errorMessage && (
                    <p className="text-red-500 mt-4 text-sm">{errorMessage}</p>
                )}

                {/* Modal Buttons */}
                <div className="mt-6 flex justify-end gap-4">
                    <button
                        onClick={handleDelete}
                        className={`px-4 py-2 text-white rounded-md transition ${
                            isDeleting ? "bg-red-300 cursor-not-allowed" : "bg-red-500 hover:bg-red-600"
                        }`}
                        disabled={isDeleting}
                    >
                        {isDeleting ? "Deleting..." : "Yes, Delete"}
                    </button>
                    <button
                        onClick={closeModal}
                        className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default DeleteUserButton;
