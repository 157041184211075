import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Select from 'react-select';
import Modal from 'react-modal';
import './SearchResults.css';
import axios from "../../custom-axios/axios-auth";
import ReactGA from "react-ga4";
import {useSearchContext} from "../../contexts/SearchContext";
import Autocomplete from "./SearchAutocomplete";
import useSearch from "../../hooks/useSearch";
import ShopCard from './ShopCard';
import CustomSelect from "./CustomSelect";
import {useMediaQuery} from "react-responsive";
import {Menu} from "@headlessui/react";
import {Bars3Icon} from "@heroicons/react/24/outline";

Modal.setAppElement('#root'); // Necessary for accessibility when using modals.

const SearchResults = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {shops, zipCode: initialZipCode} = location.state || {shops: [], zipCode: ''};
    const {selectedType, setSelectedType, zipCode, setZipCode} = useSearchContext();

    const [selectedCategoryFilters, setSelectedCategoryFilters] = useState([]);
    const [selectedCategoryService, setSelectedCategoryService] = useState([]);
    const [selectedCategoryHolidays, setSelectedCategoryHolidays] = useState([]);
    const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);
    const [selectedShop, setSelectedShop] = useState(null);
    const [showNearbyShops, setShowNearbyShops] = useState(true); // Control visibility of nearby shops
    const [hasFetchedNearbyShops, setHasFetchedNearbyShops] = useState(false); // Track if nearby shops have been fetched

    const isLargeScreen = useMediaQuery({minWidth: 1025}); // Large screen: 1025px and up
    const isSmallScreen = useMediaQuery({maxWidth: 1024}); // Small screen: 1024px and below
    const [filtersText, setFiltersText ] = useState("Show Filters");
    const [showFilters, setShowFilters] = useState(false);

    const [isZipValid, setIsZipValid] = useState(false);

    const toggleFilters = () => {
        setShowFilters((prev) => !prev);

        !showFilters ? setFiltersText("Hide Filters") : setFiltersText("Show filters")
    };
    const [nearbyShops, setNearbyShops] = useState([]);


    const [searchQuery, setSearchQuery] = useState('');
    const {handleSearch, error} = useSearch(zipCode, selectedType);

    const BASE_IMAGE_URL = `${process.env.REACT_APP_BACKEND_URL}/api/files/download/`;

    useEffect(() => {
        if (initialZipCode) {
            setZipCode(initialZipCode); // Set zipCode from location state (if present)
        }
    }, [initialZipCode, setZipCode]);  // Make sure zipCode is set once the location is loaded


    const typeOptions = [
        {value: 'product', label: 'Products'},
        {value: 'service', label: 'Services'}
    ];
    const handleSearchWithReset = () => {
        setNearbyShops([]);  // Clear nearby shops
        setHasFetchedNearbyShops(false);
        handleSearch();  // Perform the actual search
    };
    const toggleNearbyShopsVisibility = () => {
        setShowNearbyShops(prevState => !prevState);
    };
    const handleZipChange = (zip) => {
        setZipCode(zip); // Update zip code in context
        const isValid = /^\d{2,5}$/.test(zip) || zip.length === 0; // Validate zip code (2-5 digits)
        setIsZipValid(isValid); // Set validity state
    };

    useEffect(() => {
        handleZipChange(zipCode); // Validate on component mount
    }, [zipCode]);
    const groupNearbyShopsByZip = (nearbyShops) => {
        return nearbyShops.reduce((acc, shopExtended) => {
            const zipCode = shopExtended.zipCodeDTO.zipCode;
            if (!acc[zipCode]) {
                acc[zipCode] = {
                    zipCode: shopExtended.zipCodeDTO.zipCode,
                    city: shopExtended.zipCodeDTO.city,
                    county: shopExtended.zipCodeDTO.county,
                    state: shopExtended.zipCodeDTO.state,
                    shops: []
                };
            }
            acc[zipCode].shops.push(shopExtended.shopExportDTO);
            return acc;
        }, {});
    };
    const filterShopsWithZipData = (shopsExtended, searchQuery, selectedCategoryFilters, selectedCategoryService, selectedCategoryHolidays) => {
        return shopsExtended.filter((shopExtended) => {
            const shop = shopExtended.shopExportDTO;

            const matchesCategoryFilters =
                selectedCategoryFilters.length === 0 ||
                selectedCategoryFilters.some((filter) =>
                    shop.categoryFilters.some((category) => category.name.toLowerCase() === filter.toLowerCase())
                );
            const matchesCategoryService =
                selectedCategoryService.length === 0 ||
                selectedCategoryService.some((filter) =>
                    shop.categoryService.some((category) => category.name.toLowerCase() === filter.toLowerCase())
                );

            const matchesCategoryHolidays =
                selectedCategoryHolidays.length === 0 ||
                selectedCategoryHolidays.some((holiday) =>
                    shop.categoryHolidays.some((category) => category.name.toLowerCase() === holiday.toLowerCase())
                );

            const matchesSearchQuery =
                searchQuery === '' ||
                shop.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                shop.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (shop.sellerInfo?.fullName || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
                shop.categoryFilters.some((category) =>
                    category.name.toLowerCase().includes(searchQuery.toLowerCase())
                ) ||
                shop.categoryService.some((category) =>
                    category.name.toLowerCase().includes(searchQuery.toLowerCase())
                ) ||
                shop.categoryHolidays.some((category) =>
                    category.name.toLowerCase().includes(searchQuery.toLowerCase())
                );

            return matchesCategoryFilters &&matchesCategoryService && matchesCategoryHolidays && matchesSearchQuery;
        });
    };

    const filteredNearbyShops = filterShopsWithZipData(nearbyShops, searchQuery, selectedCategoryFilters, selectedCategoryService, selectedCategoryHolidays);

// Group the filtered shops by zip code, city, etc.
    const groupedFilteredNearbyShops = groupNearbyShopsByZip(filteredNearbyShops);

    // Unique categories for filters and holidays
    const uniqueCategoryFilters = [
        ...new Set(shops.flatMap((shop) => shop.categoryFilters.map((category) => category.name))),
    ].map((category) => ({label: category, value: category}));
    const uniqueCategoryService = [
        ...new Set(shops.flatMap((shop) => shop.categoryService.map((category) => category.name))),
    ].map((category) => ({label: category, value: category}));

    // const uniqueCategoryHolidays = [
    //     ...new Set(shops.flatMap((shop) => shop.categoryHolidays.map((category) => category.name))),
    // ].map((category) => ({ label: category, value: category }));

    const today = new Date();

    const uniqueCategoryHolidays = [
        ...new Set(
            shops.flatMap((shop) =>
                shop.categoryHolidays
                    .filter((category) => {
                        // Parse the date
                        const holidayDate = new Date(category.date);

                        // Calculate the difference in time and convert it to days
                        const timeDifference = holidayDate.getTime() - today.getTime();
                        const daysDifference = timeDifference / (1000 * 3600 * 24);

                        // Return true if the holiday is within 30 days
                        return daysDifference >= 0 && daysDifference <= 30;
                    })
                    .map((category) => category.name)
            )
        )
    ].map((category) => ({label: category, value: category}));


    // Handle selecting/unselecting categories
    const handleCategoryFilterChange = (selectedOptions) => {
        setSelectedCategoryFilters(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };
    const handleCategoryServiceChange = (selectedOptions) => {
        setSelectedCategoryService(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const handleCategoryHolidayChange = (selectedOptions) => {
        setSelectedCategoryHolidays(selectedOptions ? selectedOptions.map(option => option.value) : []);
    };

    const filteredShops = shops.filter((shop) => {
        // Filter by category filters
        const matchesCategoryFilters =
            selectedCategoryFilters.length === 0 ||
            selectedCategoryFilters.some((filter) =>
                shop.categoryFilters.some((category) => category.name.toLowerCase() === filter.toLowerCase())
            );

        const matchesCategoryService =
            selectedCategoryService.length === 0 ||
            selectedCategoryService.some((filter) =>
                shop.categoryService.some((category) => category.name.toLowerCase() === filter.toLowerCase())
            );

        // Filter by category holidays
        const matchesCategoryHolidays =
            selectedCategoryHolidays.length === 0 ||
            selectedCategoryHolidays.some((holiday) =>
                shop.categoryHolidays.some((category) => category.name.toLowerCase() === holiday.toLowerCase())
            );

        // Filter by search query for name, description, seller name, or categories
        const matchesSearchQuery =
            searchQuery === '' ||
            shop.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            shop.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (shop.sellerInfo?.fullName || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
            shop.categoryFilters.some((category) =>
                category.name.toLowerCase().includes(searchQuery.toLowerCase())
            ) ||
            shop.categoryService.some((category) =>
                category.name.toLowerCase().includes(searchQuery.toLowerCase())
            ) ||
            shop.categoryHolidays.some((category) =>
                category.name.toLowerCase().includes(searchQuery.toLowerCase())
            );


        return matchesCategoryFilters && matchesCategoryService && matchesCategoryHolidays && matchesSearchQuery;
    });

    const handleFindNearbyShops = async () => {
        try {
            const response = await axios.get('/home/search/nearby', {
                params: {
                    zip: zipCode,     // Current zip code
                    type: selectedType  // Selected product/service type
                }
            });
            setNearbyShops(response.data);  // Update the nearby shops state
            setHasFetchedNearbyShops(true);  // Mark that nearby shops have been fetched
        } catch (err) {
            console.error("Error fetching nearby shops: ", err);
        }
    };


    const handleShopClick = (shop) => {
        ReactGA.event({
            category: 'Shop',
            action: 'ShopClick',
            label: shop.id
        });

        navigate(`/shop-details/${shop.id}`);
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate back to the previous page, just like a browser back button
    };

    const handleOpenCategoriesModal = (shop) => {
        setSelectedShop(shop);
        setIsCategoriesModalOpen(true);
    };

    const handleCloseCategoriesModal = () => {
        setIsCategoriesModalOpen(false);
        setSelectedShop(null);
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(
                    <svg key={i} className="w-4 h-4 text-yellow-300" xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor" viewBox="0 0 22 20">
                        <path
                            d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                );
            } else {
                stars.push(
                    <svg key={i} className="w-4 h-4 text-gray-300" xmlns="http://www.w3.org/2000/svg"
                         fill="currentColor" viewBox="0 0 22 20">
                        <path
                            d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                    </svg>
                );
            }
        }
        return stars;
    };

    return (
        <div className="min-h-screen bg-white font-poppins">
            <div className="p-4 max-w-7xl mx-auto">
                {/* Header */}
                <div className="flex flex-col md:flex-row items-center mb-6 gap-4">
                    <button
                        className="text-primaryServiceColor hover:text-darkBrown font-bold transition duration-300"
                        onClick={handleBackClick}
                    >
                        &#8592; Back
                    </button>

                    {/* Input, Dropdown, and Button Container */}
                    <div
                        className="m-auto flex flex-col md:flex-row items-center space-x-0 md:space-x-4 w-full md:w-auto mt-4 md:mt-0 gap-4">
                        {/* Autocomplete for ZIP Code */}
                        <div className="w-full md:w-auto flex-grow">
                            <Autocomplete zipCode={zipCode} setZip={setZipCode}/>
                        </div>

                        {/* Dropdown for Product/Service */}
                        <div className="w-full md:w-auto flex-grow">
                            <CustomSelect
                                typeOptions={typeOptions}
                                selectedType={selectedType}
                                setSelectedType={setSelectedType}
                            />
                        </div>

                        {/* Search Button */}
                        <button
                            onClick={handleSearchWithReset}
                            className={`custom-btn-primary w-full md:w-auto flex-grow px-4 py-2 ${!isZipValid && zipCode !== '' ? 'cursor-not-allowed opacity-50' : ''}`} // Add styles for disabled button
                            style={{
                                backgroundColor: 'var(--search-btn-color)',
                                color: 'var(--search-btn-text)',
                                border: '2px solid var(--circle-border-color)',
                                whiteSpace: 'nowrap',
                            }}
                            disabled={!isZipValid && zipCode !== ''}  // Disable button if ZIP code is invalid and not empty
                            title={
                                !isZipValid && zipCode !== '' ?
                                    'Enter a valid 2-5 digit ZIP code' :
                                    (zipCode === '' ? 'Leave blank to search for all products/services' : '')
                            }  // Tooltip text based on the ZIP code validity and whether it's empty
                        >
                            Search
                        </button>

                    </div>
                </div>

                <div className="flex flex-col md:flex-row gap-4">
                    {/* Sidebar for Filters */}
                    {isLargeScreen ? (
                        <aside className="w-full md:w-1/4 bg-white p-4 rounded-lg shadow-md">
                            <h2 className="text-lg font-semibold text-darkBrown mb-4">Search Shops</h2>
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Find the shop you are looking for"
                                className="w-full p-2 mb-6 border border-lightBrown rounded-lg"
                            />
                            <h2 className="text-lg font-semibold text-darkBrown mb-4">Category Filters</h2>
                            {selectedType ==='product' ?(
                                <Select
                                    options={uniqueCategoryFilters}
                                    isMulti
                                    onChange={handleCategoryFilterChange}
                                    value={uniqueCategoryFilters.filter(option => selectedCategoryFilters.includes(option.value))}
                                    placeholder="Select categories..."
                                    className="mb-6"
                                    classNamePrefix="select"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: '#d5b496',
                                            fontFamily: 'Poppins, sans-serif',
                                            boxShadow: 'none',
                                            '&:hover': {borderColor: '#acc1b3'}
                                        }),
                                    }}
                                />):(
                                <Select
                                    options={uniqueCategoryService}
                                    isMulti
                                    onChange={handleCategoryServiceChange}
                                    value={uniqueCategoryService.filter(option => selectedCategoryService.includes(option.value))}
                                    placeholder="Select categories..."
                                    className="mb-6"
                                    classNamePrefix="select"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: '#d5b496',
                                            fontFamily: 'Poppins, sans-serif',
                                            boxShadow: 'none',
                                            '&:hover': {borderColor: '#acc1b3'}
                                        }),
                                    }}
                                />
                            )}

                            <h2 className="text-lg font-semibold text-darkBrown mb-4">Category Holidays</h2>
                            <Select
                                options={uniqueCategoryHolidays}
                                isMulti
                                onChange={handleCategoryHolidayChange}
                                value={uniqueCategoryHolidays.filter(option => selectedCategoryHolidays.includes(option.value))}
                                placeholder="Select holidays..."
                                className="mb-4"
                                classNamePrefix="select"
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        borderColor: '#d5b496',
                                        fontFamily: 'Poppins, sans-serif',
                                        boxShadow: 'none',
                                        '&:hover': {borderColor: '#acc1b3'}
                                    }),
                                }}
                            />
                        </aside>

                    ) : (
                        <aside className="w-full md:w-1/4 bg-white p-4 rounded-lg shadow-md">
                            {/* Show ellipsis only on small screens */}
                            <span onClick={toggleFilters}
                                  className="inline-flex items-center justify-center rounded-md p-2 mb-2 text-primaryServiceColor hover:bg-primaryServiceColor hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ml-auto">
                                <span className="sr-only">More filters</span>
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true"/> {filtersText}
                            </span>
                            {showFilters&& (
                                <>
                                    <h2 className="text-lg font-semibold text-darkBrown mb-4">Search Shops</h2>
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Find the shop you are looking for"
                                        className="w-full p-2 mb-6 border border-lightBrown rounded-lg"
                                    />

                                    <h2 className="text-lg font-semibold text-darkBrown mb-4">Category Filters</h2>
                                    {selectedType ==='product' ?(
                                            <Select
                                                options={uniqueCategoryFilters}
                                                isMulti
                                                onChange={handleCategoryFilterChange}
                                                value={uniqueCategoryFilters.filter(option => selectedCategoryFilters.includes(option.value))}
                                                placeholder="Select categories..."
                                                className="mb-6"
                                                classNamePrefix="select"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderColor: '#d5b496',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        boxShadow: 'none',
                                                        '&:hover': {borderColor: '#acc1b3'}
                                                    }),
                                                }}
                                            />):
                                        (
                                            <Select
                                                options={uniqueCategoryService}
                                                isMulti
                                                onChange={handleCategoryServiceChange}
                                                value={uniqueCategoryService.filter(option => selectedCategoryService.includes(option.value))}
                                                placeholder="Select categories..."
                                                className="mb-6"
                                                classNamePrefix="select"
                                                styles={{
                                                    control: (provided) => ({
                                                        ...provided,
                                                        borderColor: '#d5b496',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        boxShadow: 'none',
                                                        '&:hover': {borderColor: '#acc1b3'}
                                                    }),
                                                }}
                                            />
                                        )}

                                    <h2 className="text-lg font-semibold text-darkBrown mb-4">Category Holidays</h2>
                                    <Select
                                        options={uniqueCategoryHolidays}
                                        isMulti
                                        onChange={handleCategoryHolidayChange}
                                        value={uniqueCategoryHolidays.filter(option => selectedCategoryHolidays.includes(option.value))}
                                        placeholder="Select holidays..."
                                        className="mb-4"
                                        classNamePrefix="select"
                                        styles={{
                                            control: (provided) => ({
                                                ...provided,
                                                borderColor: '#d5b496',
                                                fontFamily: 'Poppins, sans-serif',
                                                boxShadow: 'none',
                                                '&:hover': {borderColor: '#acc1b3'}
                                            }),
                                        }}
                                    />
                                </>
                            )}
                        </aside>
                    )
                    }

                    {/* Main Content for Shops */}
                    <main className="w-full md:w-3/4 bg-white p-4 rounded-lg shadow-md">
                        {filteredShops.length === 0 ? (
                            <div className="text-center">
                                <p className="text-lg text-gray-600">No shops found.</p>
                            </div>
                        ) : (
                            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {filteredShops.map((shop) => (
                                    <ShopCard
                                        key={shop.shopId}
                                        shop={shop}
                                        handleShopClick={handleShopClick}
                                        BASE_IMAGE_URL={BASE_IMAGE_URL}
                                    />
                                ))}
                            </div>
                        )}
                        {showNearbyShops && Object.keys(groupedFilteredNearbyShops).length > 0 && Object.keys(groupedFilteredNearbyShops).map((zipCode) => (
                            <div key={zipCode} className="mt-8">
                                <h2 className="text-xl font-semibold text-darkBrown mb-4">
                                    Nearby shops
                                    for {groupedFilteredNearbyShops[zipCode].zipCode}, {groupedFilteredNearbyShops[zipCode].city}, {groupedFilteredNearbyShops[zipCode].county}, {groupedFilteredNearbyShops[zipCode].state || ''}:
                                </h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                    {groupedFilteredNearbyShops[zipCode].shops.length > 0 ? (
                                        groupedFilteredNearbyShops[zipCode].shops.map((shop) => (
                                            <ShopCard
                                                key={shop.shopId}
                                                shop={shop}
                                                handleShopClick={handleShopClick}
                                                BASE_IMAGE_URL={BASE_IMAGE_URL}
                                            />
                                        ))
                                    ) : (
                                        <p className="text-red-500">No shops available for this area.</p>
                                    )}
                                </div>
                            </div>
                        ))}


                    </main>
                </div>
            </div>

            {/* Categories Modal */
            }
            {
                isCategoriesModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                        <div className="bg-bgGrayOrange p-8 rounded-xl shadow-2xl max-w-sm w-full font-quicksand relative">
                            <button
                                onClick={handleCloseCategoriesModal}
                                className="absolute top-4 right-4 text-darkBrown hover:text-primaryGreen transition duration-300 text-2xl"
                            >
                                &times;
                            </button>
                            <h2 className="text-3xl font-bold text-darkBrown mb-6 text-center">
                                {selectedShop?.name} - Categories
                            </h2>
                            <ul className="list-disc list-inside text-darkBrown text-base mb-6">
                                {selectedShop?.categoryFilters.map((category, index) => (
                                    <li key={index} className="mb-2">{category.name}</li>
                                ))}
                            </ul>
                            <div className="flex justify-center">
                                <button
                                    onClick={handleCloseCategoriesModal}
                                    className="custom-btn-primary px-6 py-3"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="flex justify-center">
                {!hasFetchedNearbyShops ? (
                    <button
                        onClick={handleFindNearbyShops}
                        className="custom-btn-primary px-6 py-3 mb-4"
                    >
                        Find more shops from nearby zip codes
                    </button>
                ) : (
                    <button
                        onClick={toggleNearbyShopsVisibility}
                        className="custom-btn-primary px-6 py-3"
                    >
                        {showNearbyShops ? "Hide Nearby Shops" : "Show Nearby Shops"}
                    </button>
                )}
            </div>


        </div>
    )
        ;
};

export default SearchResults;

