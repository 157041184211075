import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import authenticatedInstance from '../../custom-axios/axios-auth';
import {jwtDecode} from 'jwt-decode';
import './ShopDetails.css';
import moment from 'moment';
import useOnlineStatus from "../OnlineStatus/useOnlineStatus";
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa';
import useMobilePhoneChange from "../../hooks/useMobilePhoneChange";
import PhoneInput from "react-phone-input-2";

const SellerDetails = ({ sellerProfile, userId }) => {
    const [isEditingBio, setIsEditingBio] = useState(false);
    const [isEditingFirstName, setIsEditingFirstName] = useState(false);
    const [isEditingLastName, setIsEditingLastName] = useState(false);
    const [newBio, setNewBio] = useState(sellerProfile.bio);
    const [newFirstName, setNewFirstName] = useState(sellerProfile.firstName);
    const [newLastName, setNewLastName] = useState(sellerProfile.lastName);
    const [originalBio, setOriginalBio] = useState(sellerProfile.bio);
    const [originalFirstName, setOriginalFirstName] = useState(sellerProfile.firstName);
    const [originalLastName, setOriginalLastName] = useState(sellerProfile.lastName);
    const [profilePicture, setProfilePicture] = useState(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${sellerProfile.profilePictureFile?.id || "https://via.placeholder.com/400x250"}`
    );
    const [showFullBio, setShowFullBio] = useState(false);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const socialMediaIcons = {
        FACEBOOK: <FaFacebook className="text-blue-600 hover:text-blue-800 transition duration-300" />,
        TWITTER: <FaTwitter className="text-blue-400 hover:text-blue-600 transition duration-300" />,
        INSTAGRAM: <FaInstagram className="text-pink-500 hover:text-pink-700 transition duration-300" />,
        YOUTUBE: <FaYoutube className="text-red-600 hover:text-red-800 transition duration-300" />,
        LINKEDIN: <FaLinkedin className="text-blue-700 hover:text-blue-900 transition duration-300" />
    };

    const contactInfo = sellerProfile.contactInformation;

    const sellerId = sellerProfile?.user?.id;
    const onlineStatus = useOnlineStatus(sellerId);
    const bioRef = useRef(null);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const [isEditingPhone, setIsEditingPhone] = useState(false);
    const { newMobilePhone, handleMobilePhoneChange, updateMobilePhone, isLoading, error } = useMobilePhoneChange(sellerProfile.contactInformation.id);

    const isSeller = userId === sellerProfile.user.id;
    const handleSavePhone = async () => {
        try {
            const updatedContactInfo = await updateMobilePhone(); // Call the updateMobilePhone function from the hook
            if (updatedContactInfo) {
                // Update UI with the new mobile phone
                sellerProfile.contactInformation.mobilePhone = updatedContactInfo.mobilePhone;
                setIsEditingPhone(false); // Exit the editing mode
            }
        } catch (err) {
            console.error("Failed to save phone", err);
        }
    };

    const handleSocialMediaLinkClick = (url) => {
        window.open(url, "_blank");
    };

    const handleBioChange = (e) => {
        setNewBio(e.target.value);
    };

    const handleFirstNameChange = (e) => {
        setNewFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setNewLastName(e.target.value);
    };

    const saveChanges = async (field, value) => {
        try {
            const updatedProfile = { [field]: value };
            const response = await authenticatedInstance.put(`/seller/${sellerProfile.id}/${field}`, updatedProfile);
            if (response.status === 200) {
                if (field === 'bio') {
                    setIsEditingBio(false);
                    setOriginalBio(value);
                    sellerProfile.bio = value;
                } else if (field === 'firstName') {
                    setIsEditingFirstName(false);
                    setOriginalFirstName(value);
                    sellerProfile.firstName = value;
                } else if (field === 'lastName') {
                    setIsEditingLastName(false);
                    setOriginalLastName(value);
                    sellerProfile.lastName = value;
                }
            } else {
                throw new Error(`Failed to update ${field}`);
            }
        } catch (error) {
            console.error(`Error saving ${field}:`, error);
        }
    };

    const cancelEdit = (field) => {
        if (field === 'bio') {
            setNewBio(originalBio);
            setIsEditingBio(false);
        } else if (field === 'firstName') {
            setNewFirstName(originalFirstName);
            setIsEditingFirstName(false);
        } else if (field === 'lastName') {
            setNewLastName(originalLastName);
            setIsEditingLastName(false);
        }
    };

    const handleOutsideClick = (e) => {
        if (bioRef.current && !bioRef.current.contains(e.target)) {
            if (isEditingBio && newBio !== originalBio) {
                if (window.confirm("Do you want to save the changes to the bio?")) {
                    saveChanges('bio', newBio);
                } else {
                    cancelEdit('bio');
                }
            } else {
                setIsEditingBio(false);
            }
        }

        if (firstNameRef.current && !firstNameRef.current.contains(e.target)) {
            if (isEditingFirstName && newFirstName !== originalFirstName) {
                if (window.confirm("Do you want to save the changes to the first name?")) {
                    saveChanges('firstName', newFirstName);
                } else {
                    cancelEdit('firstName');
                }
            } else {
                setIsEditingFirstName(false);
            }
        }

        if (lastNameRef.current && !lastNameRef.current.contains(e.target)) {
            if (isEditingLastName && newLastName !== originalLastName) {
                if (window.confirm("Do you want to save the changes to the last name?")) {
                    saveChanges('lastName', newLastName);
                } else {
                    cancelEdit('lastName');
                }
            } else {
                setIsEditingLastName(false);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isEditingBio, isEditingFirstName, isEditingLastName, newBio, newFirstName, newLastName, originalBio, originalFirstName, originalLastName]);

    const handleProfilePictureClick = () => {
        if (userId === sellerProfile.user.id) {
            fileInputRef.current.click();
        }
    };

    const handleProfilePictureChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);

            try {
                const response = await authenticatedInstance.put(
                    `/seller/${sellerProfile.id}/profile-picture`,
                    formData,
                    { headers: { 'Content-Type': 'multipart/form-data' } }
                );

                if (response.status === 200 && response.data) {

                    // Append a timestamp to ensure the URL is treated as unique by the browser
                    const timestamp = new Date().getTime();
                    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${response.data.id}?t=${timestamp}`;
                    setProfilePicture(imageUrl);
                } else {
                    throw new Error('Failed to update profile picture');
                }
            } catch (error) {
                console.error('Error updating profile picture:', error);
            }
        }
    };


    const handleChatClick = async () => {
        try {
            const token = localStorage.getItem('JWT');
            if (token) {
                const decoded = jwtDecode(token);
                const userId = String(decoded.userId);
                navigate('/chat', {
                    state: {
                        userId: userId,
                        sellerId: sellerProfile.user.id,
                        sellerFullName: `${sellerProfile.firstName} ${sellerProfile.lastName}`
                    }
                });
            }
        } catch (error) {
            console.error('Error initiating chat:', error);
        }
    };


    return (
        <div className="seller-details flex flex-col items-center space-y-6 p-6 rounded-lg font-poppins">
            {/* Profile Picture and Online Status */}
            <div className="relative">
                <img
                    src={profilePicture}
                    alt="Seller"
                    className="w-32 h-32 rounded-full border-4 border-lightBrown shadow-md object-cover"
                    onClick={handleProfilePictureClick}
                />
                {/* File input for changing the profile picture if the user is the seller */}
                {userId === sellerProfile.user.id && (
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{display: 'none'}}
                        onChange={handleProfilePictureChange}
                        accept="image/*"
                    />
                )}
                {/* Online status indicator */}
                <span
                    className={`absolute bottom-2 right-2 w-4 h-4 rounded-full ${
                        onlineStatus.isOnline ? 'bg-green-500' : 'bg-red-500'
                    }`}
                ></span>
            </div>

            {/* First Name, Last Name, and Zip Code + Last Active */}
            <div className="text-center">
                {userId === sellerProfile.user.id ? (
                    <>
                        {/* Editable First Name */}
                        {isEditingFirstName ? (
                            <input
                                type="text"
                                value={newFirstName}
                                onChange={handleFirstNameChange}
                                onBlur={() => saveChanges('firstName', newFirstName)}
                                autoFocus
                                className="border-2 border-gray-300 rounded-lg p-2 w-full mb-1 font-quicksand"
                            />
                        ) : (
                            <p
                                onClick={() => setIsEditingFirstName(true)}
                                className="font-semibold text-lg hover:text-lightBrown cursor-pointer"
                            >
                                {newFirstName}
                            </p>
                        )}

                        {/* Editable Last Name */}
                        {isEditingLastName ? (
                            <input
                                type="text"
                                value={newLastName}
                                onChange={handleLastNameChange}
                                onBlur={() => saveChanges('lastName', newLastName)}
                                autoFocus
                                className="border-2 border-gray-300 rounded-lg p-2 w-full font-quicksand"
                            />
                        ) : (
                            <p
                                onClick={() => setIsEditingLastName(true)}
                                className="font-semibold text-lg hover:text-lightBrown cursor-pointer"
                            >
                                {newLastName}
                            </p>
                        )}
                    </>
                ) : (
                    <p className="font-semibold text-lg">
                        {sellerProfile.firstName} {sellerProfile.lastName}
                    </p>
                )}

                {/* Zip Code, Last Active, and Chat Button on the same line */}
                <div className="flex items-center text-xs text-gray-500 mt-2 w-full px-8 space-x-2">
                    <p>Zip Code: {sellerProfile.shop.zipCode}</p>

                    {/* Separator */}
                    <span className="mx-2">|</span>

                    {onlineStatus.lastActivityTime && (
                        <>
                            <p>Last active: {new Date(onlineStatus.lastActivityTime + 'Z').toLocaleString()}</p>

                            {/* Separator for Chat button, only if not the seller */}
                            {userId && userId !== sellerProfile.user.id && <span className="mx-2">|</span>}
                        </>
                    )}

                    {/* Chat with Seller Button */}
                    {userId && userId !== sellerProfile.user.id && (
                        <button
                            onClick={handleChatClick}
                            className="text-primaryGreen hover:underline ml-1 font-raleway"
                        >
                            Chat with {sellerProfile.firstName} {sellerProfile.lastName}
                        </button>
                    )}
                </div>
            </div>

            {/* Social Media Links and Contact Information Button */}
            <div className="flex items-center mt-4 w-full px-8 space-x-4">
                {/* Social Media Icons */}
                <div className="flex space-x-4">
                    {sellerProfile.socialMediaLinks.map((link) => {
                        // Ensure the URL starts with http:// or https://
                        const formattedUrl = link.url.startsWith('http://') || link.url.startsWith('https://')
                            ? link.url
                            : `https://${link.url}`;

                        return (
                            <a
                                key={link.platform}
                                href={formattedUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {socialMediaIcons[link.platform.toUpperCase()] || ""}
                            </a>
                        );
                    })}
                </div>

                {/* Separator */}
                <div className="border-l-2 border-gray-300 h-6"></div>

                {/* Contact Information Button */}
                <button
                    onClick={() => setIsContactModalOpen(true)}
                    className="text-sm text-primaryGreen hover:underline font-raleway"
                >
                    Contact Information
                </button>
            </div>


            {/* Contact Information Modal */}
            {isContactModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-96 font-lora">
                        <h2 className="text-2xl font-bold mb-4 text-center">Contact Information</h2>

                        {sellerProfile.contactInformation ? (
                            <div className="text-sm text-gray-600 space-y-2">
                                <p>Email: {sellerProfile.contactInformation.email}</p>

                                {/* Mobile Phone */}
                                <div className="flex items-center justify-between">
                                    {userId === sellerProfile.user.id ? (
                                        isEditingPhone ? (
                                            <div className="w-full">
                                                <label className="block text-lg font-semibold mb-2">Mobile Phone</label>
                                                <PhoneInput
                                                    country={'us'}
                                                    value={newMobilePhone || sellerProfile.contactInformation.mobilePhone}
                                                    onChange={handleMobilePhoneChange}
                                                    enableSearch={true}
                                                    inputStyle={{
                                                        width: '100%',
                                                        padding: '10px',
                                                        borderRadius: '8px',
                                                        border: '1px solid #ccc',
                                                        backgroundColor: '#f9f9f9',
                                                        color: '#333',
                                                        paddingLeft: '50px',
                                                    }}
                                                    buttonStyle={{
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        padding: '0 10px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                                <div className="mt-4 flex justify-between">
                                                    <button
                                                        onClick={handleSavePhone} // Handle saving phone number
                                                        className="px-4 py-2 bg-blue-500 text-white rounded"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? 'Saving...' : 'Save'}
                                                    </button>
                                                    <button
                                                        onClick={() => setIsEditingPhone(false)} // Cancel editing
                                                        className="px-4 py-2 bg-gray-300 text-black rounded"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                {error && <p className="text-red-500 mt-2">{error}</p>}
                                            </div>
                                        ) : (
                                            <div className="w-full">
                                                <label className="block text-lg font-semibold mb-2">Mobile Phone</label>
                                                <PhoneInput
                                                    country={'us'}
                                                    value={sellerProfile.contactInformation.mobilePhone}
                                                    onClick={() => setIsEditingPhone(true)} // Switch to editing mode on click
                                                    inputStyle={{
                                                        width: '100%',
                                                        padding: '10px',
                                                        borderRadius: '8px',
                                                        border: '1px solid #ccc',
                                                        backgroundColor: '#f9f9f9',
                                                        color: '#333',
                                                        paddingLeft: '50px',
                                                    }}
                                                    buttonStyle={{
                                                        border: 'none',
                                                        backgroundColor: 'transparent',
                                                        padding: '0 10px',
                                                        marginRight: '10px',
                                                    }}
                                                />
                                            </div>
                                        )
                                    ) : (
                                        <PhoneInput
                                            country={'us'}
                                            value={sellerProfile.contactInformation.mobilePhone}
                                            disabled={true}
                                            inputStyle={{
                                                width: '100%',
                                                padding: '10px',
                                                borderRadius: '8px',
                                                border: '1px solid #ccc',
                                                backgroundColor: '#f9f9f9',
                                                color: '#333',
                                                paddingLeft: '50px',
                                            }}
                                            buttonStyle={{
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                padding: '0 10px',
                                                marginRight: '10px',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <p className="text-sm text-gray-600">No contact information provided</p>
                        )}

                        {/* Close Button */}
                        <div className="mt-4 text-right">
                            <button
                                onClick={() => setIsContactModalOpen(false)}
                                className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}










            {/* Seller Bio Section with 'Show more'/'Show less' functionality */}
            <div className="text-left mt-4 w-full px-8 font-nunito">
                {userId === sellerProfile.user.id ? (
                    isEditingBio ? (
                        <textarea
                            value={newBio}
                            onChange={handleBioChange}
                            onBlur={() => saveChanges('bio', newBio)}
                            autoFocus
                            className="border-2 border-gray-300 rounded-lg p-2 w-full h-24"
                        />
                    ) : (
                        <p
                            onClick={() => setIsEditingBio(true)}
                            className="text-gray-700 hover:text-lightBrown cursor-pointer"
                        >
                            {newBio.length > 100 && !showFullBio ? (
                                <>
                                    {newBio.slice(0, 100)}...{' '}
                                    <span
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => setShowFullBio(true)}
                                    >
                                    Show more
                                </span>
                                </>
                            ) : showFullBio ? (
                                <>
                                    {newBio}{' '}
                                    <span
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => setShowFullBio(false)}
                                    >
                                    Show less
                                </span>
                                </>
                            ) : (
                                newBio
                            )}
                        </p>
                    )
                ) : (
                    <p className="text-gray-700">
                        {sellerProfile.bio && sellerProfile.bio.length > 100 && !showFullBio ? (
                            <>
                                {sellerProfile.bio.slice(0, 100)}...{' '}
                                <span
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() => setShowFullBio(true)}
                                >
                                Show more
                            </span>
                            </>
                        ) : showFullBio ? (
                            <>
                                {sellerProfile.bio}{' '}
                                <span
                                    className="text-blue-500 cursor-pointer"
                                    onClick={() => setShowFullBio(false)}
                                >
                                Show less
                            </span>
                            </>
                        ) : (
                            sellerProfile.bio || 'No bio provided'
                        )}
                    </p>
                )}
            </div>
        </div>
    );


};

export default SellerDetails;
