import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import authenticatedInstance from '../../custom-axios/axios-auth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format} from 'date-fns';
import {EllipsisVerticalIcon} from '@heroicons/react/24/solid';
import ReactGA from "react-ga4";
import useEditProductPrice from "../../hooks/useEditProductPrice";

const ProductList = ({products, userId, sellerProfile}) => {
    const [editingProductId, setEditingProductId] = useState(null);
    const [editingTitleProductId, setEditingTitleProductId] = useState(null);
    const [editingPriceProductId, setEditingPriceProductId] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [originalTitle, setOriginalTitle] = useState('');
    const [showAddForm, setShowAddForm] = useState(false);
    const [newProduct, setNewProduct] = useState({
        title: '',
        price:'',
        description: '',
        images: [],
        imageFiles:[],
        shopId: sellerProfile.shop.id,
        productType: 'PRODUCT',
    });
    const [productsState, setProductsState] = useState(products || []);
    const [showImageSelection, setShowImageSelection] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [quantity, setQuantity] = useState({});
    const [selectedDate, setSelectedDate] = useState({});
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');
    const [isOrderFormOpen, setIsOrderFormOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [additionalMessage, setAdditionalMessage] = useState('');
    const [showProductOptions, setShowProductOptions] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showHidden, setShowHidden] = useState(false);
    const [newPrice, setNewPrice] = useState('');
    const { changePrice, isLoading, error } = useEditProductPrice();


    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
    }, [productsState]);

    const navigate = useNavigate();
    const titleRef = useRef(null);

    const headingTitle = sellerProfile.shop.productType === 'SERVICE' ? 'Services' : 'Products';
    const headingColor = sellerProfile.shop.productType === 'SERVICE' ? '#1e3f16' : '#B22a7a';  // primaryServiceColor for service, primaryProductColor for product

    // Handle changing the product type
    const handleProductTypeChange = async (productId) => {
        try {
            // Find the product and toggle its type between 'PRODUCT' and 'SERVICE'
            const updatedProduct = productsState.find(product => product.id === productId);
            if (updatedProduct) {
                const newProductType = updatedProduct.productType === 'PRODUCT' ? 'SERVICE' : 'PRODUCT';

                // Send the request to update product type
                const response = await authenticatedInstance.put(`/products/${productId}/type`, null, {
                    params: {value: newProductType}
                });

                if (response.status === 200) {
                    // Update product in the state with the updated product from response
                    const updatedProductFromResponse = response.data;
                    setProductsState(productsState.map(product =>
                        product.id === productId ? updatedProductFromResponse : product
                    ));
                } else {
                    throw new Error('Failed to update product type');
                }
            }
        } catch (error) {
            console.error('Error changing product type:', error);
        }
    };

// Handle hiding the product
    const handleHideProduct = async (productId) => {
        try {
            // Send the request to hide the product
            const response = await authenticatedInstance.put(`/products/${productId}/hide`, null, {
                params: {value: true}
            });

            if (response.status === 200) {
                // Update product in the state with the updated product from response
                const updatedProductFromResponse = response.data;
                setProductsState(productsState.map(product =>
                    product.id === productId ? updatedProductFromResponse : product
                ));
            } else {
                throw new Error('Failed to hide product');
            }
        } catch (error) {
            console.error('Error hiding product:', error);
        }
    };


    const handleTitleChange = (e) => {
        setNewTitle(e.target.value);
    };

    const handleProductChange = (field, value, index = null) => {
        if (field === 'price') {
            // Price validation: only digits and one decimal point allowed
            const sanitizedValue = value.replace(/[^0-9.]/g, ''); // Remove any non-numeric characters except for the decimal
            const dotCount = (sanitizedValue.match(/\./g) || []).length;

            // If more than one dot is found, restrict further input
            if (dotCount > 1) {
                setErrorMessage('Please enter a valid price (e.g., 35.99).');
                return;
            }

            setErrorMessage('');
            setNewProduct({
                ...newProduct,
                [field]: sanitizedValue,
            });
        }  else {
            setNewProduct({
                ...newProduct,
                [field]: value,
            });
        }
    };

    const handleImageChange = (e, index) => {
        const files = Array.from(e.target.files);
        const file = files[0];  // Take the first file selected

        if (file && file.type.startsWith('image/')) {
            // Only add image files
            const updatedImages = [...newProduct.imageFiles];
            updatedImages[index] = file;
            setNewProduct({ ...newProduct, imageFiles: updatedImages }); // Update imageFiles state
            setErrorMessage(''); // Reset error message if the image is valid
        } else {
            // If the file is not an image, set an error message
            setErrorMessage('Only image files can be uploaded (e.g., JPG, PNG, GIF).');
        }
    };


    const addImageField = () => {
        setNewProduct({ ...newProduct, imageFiles: [...newProduct.imageFiles, null] }); // Use imageFiles
    };

    const removeImageField = (index) => {
        const updatedImages = newProduct.imageFiles.filter((_, i) => i !== index); // Use imageFiles
        setNewProduct({ ...newProduct, imageFiles: updatedImages }); // Use imageFiles
    };

    const saveChanges = async (productId, newTitle) => {
        try {
            const updatedField = {value: newTitle};
            const response = await authenticatedInstance.put(`/products/${productId}/title`, updatedField);
            if (response.status === 200) {
                setEditingProductId(null);
                const updatedProduct = productsState.find(product => product.id === productId);
                if (updatedProduct) {
                    updatedProduct.title = newTitle;
                }
                setProductsState([...productsState]);
            } else {
                throw new Error('Failed to update product title');
            }
        } catch (error) {
            console.error('Error saving product title:', error);
        }
    };

    const cancelEdit = (productId) => {
        setNewTitle(originalTitle);
        setEditingProductId(null);
    };

    const handleOutsideClick = (e) => {
        if (titleRef.current && !titleRef.current.contains(e.target)) {
            if (editingProductId !== null && newTitle !== originalTitle) {
                if (window.confirm("Do you want to save the changes to the title?")) {
                    saveChanges(editingProductId, newTitle);
                } else {
                    cancelEdit(editingProductId);
                }
            } else {
                setEditingProductId(null);
            }
        }
    };

    const handleAddProduct = async () => {
        try {
            const formData = new FormData();
            formData.append('title', newProduct.title);
            formData.append('description', newProduct.description);
            formData.append('shopId', newProduct.shopId);
            formData.append('price', newProduct.price);
            formData.append('productType', "type");

            newProduct.imageFiles.forEach((image, index) => { // Use imageFiles
                if (image) {
                    formData.append('imageFiles', image); // Use imageFiles
                }
            });

            const response = await authenticatedInstance.post('/products', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.status === 200) {
                setShowAddForm(false);
                setProductsState([...productsState, response.data]);
                setNewProduct({
                    title: '',
                    description: '',
                    price: '',
                    imageFiles: [], // Reset imageFiles instead of images
                    shopId: sellerProfile.shop.id,
                    productType: 'type'
                });
            } else {
                throw new Error('Failed to add product');
            }
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };


    const handleSelectImage = async (productId, imageId) => {
        try {
            const response = await authenticatedInstance.put(`/products/${productId}/main-image`, null, {
                params: { imageId }
            });
            if (response.status === 200) {
                const updatedProduct = productsState.find(product => product.id === productId);
                if (updatedProduct) {
                    updatedProduct.imageFiles = response.data.imageFiles; // Use imageFiles
                }
                setProductsState([...productsState]);
                setShowImageSelection(false);
                setSelectedProductId(null);
            } else {
                throw new Error('Failed to update main image');
            }
        } catch (error) {
            console.error('Error updating main image:', error);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [editingProductId, newTitle, originalTitle]);


    const isSeller = sellerProfile.user && userId === sellerProfile.user.id;

    const handleQuantityChange = (productId, value) => {
        setQuantity(prev => ({...prev, [productId]: value}));
    };

    const handleDateChange = (productId, value) => {
        setSelectedDate(prev => ({...prev, [productId]: value}));
    };

    const handleOrderClick = (productId, productName) => {
        const quantitySelected = selectedProduct.productType === 'PRODUCT' ? quantity[productId] : 0;
        const dateSelected = selectedDate[productId];

        if (!dateSelected || (selectedProduct.productType === 'PRODUCT' && quantitySelected <= 0)) {
            alert("Please select a valid date and quantity.");
            return;
        }

        // Convert selected date to Date object if it's not already
        const selectedDateTime = new Date(dateSelected);
        const currentDateTime = new Date();

        // Check if the selected date is at least 1 hour in the future
        const oneHourLater = new Date(currentDateTime.getTime() + 60 * 60 * 1000);

        if (selectedDateTime < oneHourLater) {
            alert("Please select a date and time at least 1 hour in the future.");
            return;
        }

        const productLink = `${process.env.REACT_APP_FRONTEND_URL}/product/${productId}`;

        // Format the selected date into a readable format for the chat message
        const formattedDateForMessage = format(selectedDateTime, 'MMMM d, yyyy h:mm aa');

        // Format the date in ISO format for the backend
        const isoDateForBackend = selectedDateTime.toISOString();

        // Create the order message for the chat, depending on product type (service vs product)
        let orderMessage;
        if (quantitySelected === 0) {
            // Service message
            orderMessage = `I would like to hire you for [${productName}](${productLink}) on ${formattedDateForMessage}.`;
        } else {
            // Product message
            orderMessage = `I would like to order ${quantitySelected} units of [${productName}](${productLink}) to be delivered on ${formattedDateForMessage}.`;
        }

        // Append additional message if provided
        if (additionalMessage.trim()) {
            orderMessage += ` Additional Note: ${additionalMessage.trim()}`;
        }

        const state = {
            userId: userId,
            sellerId: sellerProfile.user.id,
            sellerFullName: sellerProfile.user.fullName,
            prefilledMessage: orderMessage,
            isOrder: true,
            orderDetails: {
                productId: productId,
                productName: productName,
                quantity: quantitySelected,
                deliveryDate: isoDateForBackend  // Send ISO format to the backend
            }
        };


        navigate('/chat', {state});
        setIsOrderFormOpen(false);
    };

    // Function to update the product list with the updated product data
    const updateProductInList = (updatedProduct) => {
        setProductsState((prevProducts) =>
            prevProducts.map((product) =>
                product.id === updatedProduct.id ? updatedProduct : product
            )
        );
    };
    const handlePriceChange = (product) => {
        setEditingProductId(product.id);
        setNewPrice(product.price);
    };
    const filteredProducts = useMemo(() => {

        return productsState.filter((product) => {


            const matchesFilter =
                selectedFilter === 'All' ||
                (selectedFilter === 'Product' && product.productType === 'PRODUCT') ||
                (selectedFilter === 'Service' && product.productType === 'SERVICE');

            const matchesSearch =
                product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                product.description.toLowerCase().includes(searchTerm.toLowerCase());

            // New logic: Only show hidden or visible products based on `showHidden`
            const matchesHidden = showHidden ? product.isHidden : !product.isHidden;


            return matchesFilter && matchesSearch && matchesHidden;
        });
    }, [productsState, selectedFilter, searchTerm, showHidden]);


    return (
        <div className="p-6 font-poppins">
            <h2 className="text-3xl font-semibold mb-6" style={{color: headingColor}}>
                {headingTitle}:
            </h2>

            {/* Filter & Search Section */}
            <div className="flex flex-wrap items-center justify-between mb-6 space-y-4 md:space-y-0 md:flex-nowrap">
                {/* See Hidden Products Toggle */}
                {isSeller && sellerProfile.subscriptionPlan === "Premium" && (
                    <div className="flex items-center space-x-4 ml-4">
                        <button
                            onClick={() => setShowHidden(!showHidden)}
                            className={`flex items-center justify-center w-36 py-2 px-4 rounded-full font-semibold transition duration-300 ${
                                showHidden ? 'bg-primaryGreen text-darkBrown' : 'bg-lightBrown text-white'
                            } hover:shadow-lg transform hover:scale-105`}
                        >
                            {showHidden ? 'Hide Hidden' : 'Show Hidden'}
                        </button>
                    </div>
                )}


                {/* Search Input */}
                <div className="w-full md:w-auto ml-4">
                    <input
                        type="text"
                        placeholder="Search by title or description"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="border border-lightBrown p-3 rounded-md w-full md:w-64 focus:outline-none focus:ring-2 focus:ring-primaryGreen bg-gray-100"
                    />
                </div>
            </div>

            {/* Products Grid */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredProducts.map((product) => (
                    <div
                        key={product.id}
                        className="max-w-[384px] mx-auto"
                        onClick={() => {
                            if (!isSeller || editingProductId !== product.id) {
                                navigate(`/product/${product.id}`);

                                ReactGA.event({
                                    category: 'Product',
                                    action: 'Click',
                                    label: product.id
                                });
                            }
                        }}
                    >
                        {/* Three Dots Menu for Sellers */}
                        {isSeller && sellerProfile.subscriptionPlan === "Premium" && (
                            <div className="absolute top-4 right-4">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowProductOptions(product.id);
                                    }}
                                >
                                    <EllipsisVerticalIcon className="w-6 h-6 text-darkBrown hover:text-primaryGreen"/>
                                </button>
                                {showProductOptions === product.id && (
                                    <div
                                        className="absolute right-0 mt-2 w-40 bg-white border border-lightBrown rounded-lg shadow-lg z-50">
                                        {/*<button*/}
                                        {/*    onClick={(e) => {*/}
                                        {/*        e.stopPropagation();*/}
                                        {/*        handleProductTypeChange(product.id);*/}
                                        {/*        setShowProductOptions(null);*/}
                                        {/*    }}*/}
                                        {/*    className="block w-full text-left px-4 py-2 text-sm text-darkBrown hover:bg-bgGrayOrange transition duration-200"*/}
                                        {/*>*/}
                                        {/*    Change Product Type*/}
                                        {/*</button>*/}
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleHideProduct(product.id);
                                                setShowProductOptions(null);
                                            }}
                                            className="block w-full text-left px-4 py-2 text-sm text-customRed hover:bg-bgGrayOrange transition duration-200"
                                        >
                                            Hide Product
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Product Image */}
                        <div className="w-full max-w-sm aspect-square">
                            <img
                                src={
                                    product.imageFiles[0]?.id
                                        ? `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${product.imageFiles[0].id}`
                                        : "https://via.placeholder.com/400x250"
                                }
                                alt={product.title}
                                className="w-full h-full rounded-xl object-cover"
                                onClick={(e) => {
                                    if (isSeller) {
                                        e.stopPropagation();
                                        setShowImageSelection(true);
                                        setSelectedProductId(product.id);
                                    }
                                }}
                            />
                        </div>


                        {/* Product Title, Price, and Order Button */}
                        <div className="mt-5 flex flex-col sm:flex-row justify-between items-center">
                            <div>
                                {/* Product Title */}
                                {isSeller ? (
                                    editingProductId === product.id ? (
                                        <div className="flex flex-col items-center space-y-2">
                                            <input
                                                ref={titleRef}
                                                type="text"
                                                value={newTitle}
                                                onChange={handleTitleChange}
                                                className="border p-2 rounded w-full max-w-xs border-darkBrown text-darkBrown text-center font-raleway font-bold text-xl"
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                            <div
                                                className="flex flex-col space-y-2">  {/* Only this line is modified */}
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        saveChanges(product.id, newTitle);
                                                    }}
                                                    className="custom-btn-primary"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        cancelEdit(product.id);
                                                    }}
                                                    className="custom-btn-outline"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>


                                    ) : (
                                        <p
                                            className="text-center sm:text-left font-medium text-xl leading-8 text-black cursor-pointer transition duration-300 hover:scale-105"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingProductId(product.id);
                                                setNewTitle(product.title);
                                                setOriginalTitle(product.title);
                                            }}
                                        >
                                            {product.title}
                                        </p>
                                    )
                                ) : (
                                    <h6 className="text-center sm:text-left font-medium text-xl leading-8 text-black mb-2">
                                        {product.title}
                                    </h6>
                                )}

                                {/* Product Price */}
                                {isSeller && editingPriceProductId === product.id ? (
                                    <div className="flex flex-col items-center space-y-2">
                                        <input
                                            type="number"
                                            value={newPrice}
                                            onChange={(e) => setNewPrice(e.target.value)} // Update price
                                            onClick={(e) => e.stopPropagation()} // Prevent event propagation on click
                                            onFocus={(e) => e.stopPropagation()} // Prevent event propagation on focus (if necessary)
                                            className="border p-2 rounded w-full max-w-xs border-darkBrown text-darkBrown text-center font-raleway font-bold text-xl"
                                        />

                                        <div className="flex flex-col space-y-2">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the click from propagating
                                                    changePrice(product.id, newPrice).then((updatedProduct) => {
                                                        if (updatedProduct) {
                                                            updateProductInList(updatedProduct); // Update product list
                                                            setEditingPriceProductId(null); // End price edit
                                                        }
                                                    });
                                                }}
                                                className="custom-btn-primary"
                                            >
                                                {isLoading ? 'Saving...' : 'Save Price'}
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent the click from propagating
                                                    setEditingPriceProductId(null);  // Cancel price edit
                                                }}
                                                className="custom-btn-outline"
                                            >
                                                Cancel
                                            </button>
                                            {error && <p className="text-red-500">{error}</p>}
                                        </div>
                                    </div>
                                ) : (
                                    <h6
                                        className="text-center sm:text-left font-semibold text-xl leading-8 text-indigo-600 cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent the click from propagating
                                            setEditingPriceProductId(product.id); // Start price edit
                                            setNewPrice(product.price); // Set new price value
                                        }}
                                    >
                                        {product.price}$
                                    </h6>
                                )}
                                {isSeller && (
                                    <div
                                        className="sm:hidden text-center btn px-4 py-2 rounded-md bg-blue-600 text-white font-semibold hover:bg-blue-700 cursor-pointer transition duration-200"
                                        onClick={() => {
                                            if (editingProductId !== product.id) {
                                                navigate(`/product/${product.id}`);

                                                ReactGA.event({
                                                    category: 'Product',
                                                    action: 'Click',
                                                    label: product.id
                                                });
                                            }
                                        }}
                                    >
                                        See details
                                    </div>
                                )}




                            </div>

                            {/* Order Button for Non-Sellers */}
                            {!isSeller && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSelectedProduct(product);
                                        setIsOrderFormOpen(true);
                                    }}
                                    className="mt-4 sm:mt-0 p-2 rounded-full bg-white border border-gray-300 flex items-center justify-center group shadow-sm transition-all duration-500 hover:shadow-gray-200 hover:border-gray-400 hover:bg-gray-50"
                                >
                                    <svg
                                        className="stroke-gray-900 transition-all duration-500 group-hover:stroke-black"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.6892 21.125C12.6892 22.0225 11.9409 22.75 11.0177 22.75C10.0946 22.75 9.34632 22.0225 9.34632 21.125M19.3749 21.125C19.3749 22.0225 18.6266 22.75 17.7035 22.75C16.7804 22.75 16.032 22.0225 16.032 21.125M4.88917 6.5L6.4566 14.88C6.77298 16.5715 6.93117 17.4173 7.53301 17.917C8.13484 18.4167 8.99525 18.4167 10.7161 18.4167H18.0056C19.7266 18.4167 20.587 18.4167 21.1889 17.9169C21.7907 17.4172 21.9489 16.5714 22.2652 14.8798L22.8728 11.6298C23.3172 9.25332 23.5394 8.06508 22.8896 7.28254C22.2398 6.5 21.031 6.5 18.6133 6.5H4.88917ZM4.88917 6.5L4.33203 3.25"
                                            stroke=""
                                            strokeWidth="1.6"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                    Order now!
                                </button>
                            )}
                        </div>

                    </div>

                ))}

                {/* Add Product Card for Sellers */}
                {isSeller && (sellerProfile?.products ?? []).length < sellerProfile?.productsLimit && (
                    <div
                        className="bg-white shadow-md rounded-xl p-4 flex items-center justify-center cursor-pointer hover:scale-105 hover:shadow-lg transition duration-300"
                        onClick={() => setShowAddForm(true)}
                    >
                        <button className="custom-btn-primary w-full">
                            Add Product
                        </button>
                    </div>
                )}
            </div>


            {/* Add New Product Modal */}
            {showAddForm && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white shadow-2xl p-8 rounded-xl max-w-md w-full relative font-quicksand">
                        <button
                            onClick={() => setShowAddForm(false)}
                            className="absolute top-4 right-4 text-darkBrown hover:text-primaryGreen transition duration-300 text-2xl"
                        >
                            &times;
                        </button>
                        <h2 className="text-3xl font-bold text-darkBrown mb-6 text-center">Add New Product</h2>

                        {/* Product Type Selection */}
                        {/*<label htmlFor="productType" className="block text-darkBrown font-semibold mb-2">Product*/}
                        {/*    Type:</label>*/}
                        {/*<select*/}
                        {/*    id="productType"*/}
                        {/*    value={newProduct.productType || "PRODUCT"}*/}
                        {/*    onChange={(e) => handleProductChange('productType', e.target.value)}*/}
                        {/*    className="w-full border p-2 rounded mb-4 border-lightBrown text-darkBrown"*/}
                        {/*>*/}
                        {/*    <option value="PRODUCT">Product</option>*/}
                        {/*    <option value="SERVICE">Service</option>*/}
                        {/*</select>*/}

                        <input
                            type="text"
                            value={newProduct.title}
                            onChange={(e) => handleProductChange('title', e.target.value)}
                            placeholder="Enter product title"
                            required
                            className="w-full border p-2 rounded mb-4 border-lightBrown text-darkBrown"

                        />
                        <input
                            type="number"
                            value={newProduct.price}
                            onChange={(e) => handleProductChange('price', e.target.value)}
                            placeholder="Enter product price"
                            required
                            className="w-full border p-2 rounded mb-4 border-lightBrown text-darkBrown"
                            step="0.01"
                        />
                        <textarea
                            value={newProduct.description}
                            onChange={(e) => handleProductChange('description', e.target.value)}
                            placeholder="Enter product description"
                            required
                            className="w-full border p-2 rounded mb-4 border-lightBrown text-darkBrown"
                        />
                        {newProduct.imageFiles.map((_, index) => (
                            <div key={index} className="flex items-center space-x-4 mb-4">
                                <input
                                    type="file"
                                    onChange={(e) => handleImageChange(e, index)}
                                    className="block w-full border p-2 rounded border-lightBrown"
                                />
                                <button
                                    className="custom-btn-remove"
                                    onClick={() => removeImageField(index)}
                                >
                                    Remove
                                </button>
                                {errorMessage && (
                                    <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
                                )}

                            </div>

                        ))}
                        <button onClick={addImageField} className="custom-btn-primary mb-4">Add Image</button>
                        <div className="flex items-center space-x-4">
                            <button onClick={handleAddProduct} onTouchStart={handleAddProduct} className="custom-btn-primary">
                                Save
                            </button>
                            <button onClick={() => setShowAddForm(false)} className="custom-btn-secondary">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {/* Image Selection Popup */}
            {showImageSelection && selectedProductId && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-xl shadow-2xl max-w-lg w-full relative font-quicksand">
                        <button
                            onClick={() => setShowImageSelection(false)}
                            className="absolute top-4 right-4 text-darkBrown hover:text-primaryGreen transition duration-300 text-2xl"
                        >
                            &times;
                        </button>

                        <h2 className="text-3xl font-bold text-darkBrown mb-6 text-center">
                            Select Main Image
                        </h2>

                        {/* Define currentImages by finding the images from the selected product */}
                        {productsState.find(product => product.id === selectedProductId) && (
                            (() => {
                                const currentImageFiles = productsState.find(product => product.id === selectedProductId).imageFiles;

                                return (
                                    <div className="flex items-center justify-center space-x-4">
                                        <button
                                            onClick={() =>
                                                setCurrentImageIndex((prevIndex) =>
                                                    prevIndex === 0 ? currentImageFiles.length - 1 : prevIndex - 1
                                                )
                                            }
                                            className="custom-btn-outline"
                                        >
                                            &#8249; Prev
                                        </button>

                                        <img
                                            key={currentImageFiles[currentImageIndex]?.id}
                                            src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_FILE_DOWNLOAD_PATH}${currentImageFiles[currentImageIndex]?.id}`}
                                            alt="Product"
                                            className="w-64 h-64 object-cover rounded-lg border border-lightBrown transition-all"
                                        />

                                        <button
                                            onClick={() =>
                                                setCurrentImageIndex((prevIndex) =>
                                                    prevIndex === currentImageFiles.length - 1 ? 0 : prevIndex + 1
                                                )
                                            }
                                            className="custom-btn-outline"
                                        >
                                            Next &#8250;
                                        </button>
                                    </div>
                                );
                            })()
                        )}

                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={() =>
                                    handleSelectImage(
                                        selectedProductId,
                                        productsState.find(product => product.id === selectedProductId).imageFiles[currentImageIndex].id
                                    )
                                }
                                className="custom-btn-primary px-6 py-3 mr-4"
                            >
                                Set as Main Image
                            </button>

                            <button
                                onClick={() => setShowImageSelection(false)}
                                className="custom-btn-secondary px-6 py-3"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {/* Order Form Modal */}
            {isOrderFormOpen && selectedProduct && (
                <div onClick={() => setIsOrderFormOpen(false)}
                     className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative font-poppins"
                         onClick={(e) => e.stopPropagation()}>
                        <h2 className="text-3xl font-bold text-darkBrown mb-6 text-center">
                            Order {selectedProduct.title}
                        </h2>

                        {/* Quantity for Product Type */}
                        {selectedProduct.productType === 'PRODUCT' && (
                            <label className="block text-sm mb-6 text-darkBrown">
                                Quantity:
                                <input
                                    type="number"
                                    value={quantity[selectedProduct.id] || ''}
                                    onChange={(e) => handleQuantityChange(selectedProduct.id, e.target.value)}
                                    min="1"
                                    className="block mt-2 w-full border p-3 rounded-lg border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                                />
                            </label>
                        )}

                        {/* Delivery Date and Time */}
                        <div className="block text-sm mb-6 text-darkBrown">
                            <label className="block mb-2">Delivery Date and Time:</label>
                            <DatePicker
                                selected={selectedDate[selectedProduct.id] || null}
                                onChange={(date) => handleDateChange(selectedProduct.id, date)}
                                showTimeSelect
                                timeFormat="hh:mm aa" // Changed to AM/PM format for better readability
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                minDate={new Date()}
                                placeholderText="Select a delivery date and time"
                                className="block w-full border p-3 rounded-lg mt-1 border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                                popperClassName="font-poppins z-50"
                                shouldCloseOnSelect={true} // Closes automatically after a selection is made
                            />
                        </div>

                        {/* Additional Message */}
                        <label className="block text-sm mb-6 text-darkBrown">
                            Additional Message (optional):
                            <textarea
                                value={additionalMessage}
                                onChange={(e) => setAdditionalMessage(e.target.value)}
                                placeholder="Any additional requests or information..."
                                className="block mt-2 w-full border p-3 rounded-lg border-lightBrown focus:outline-none focus:ring-2 focus:ring-primaryGreen"
                            />
                        </label>

                        {/* Order Button */}
                        <button
                            onClick={() => handleOrderClick(selectedProduct.id, selectedProduct.title)}
                            className="custom-btn-primary w-full mt-4"
                        >
                            Place Order
                        </button>

                        {/* Cancel Button */}
                        <button
                            onClick={() => setIsOrderFormOpen(false)}
                            className="mt-4 custom-btn-secondary w-full"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}


        </div>
    );


};

export default ProductList;
