import React, {useEffect, useState} from 'react';
import './HomePage.css';
import HomePageCards from './HomePageCards';
import Autocomplete from "../Search/SearchAutocomplete";
import {useNavigate} from 'react-router-dom';
import useThemeSwitcher from "../../hooks/useThemeSwitcher";
import useSearch from "../../hooks/useSearch";
import {productTitles, serviceTitles} from './titles';

import {useSearchContext} from "../../contexts/SearchContext";
import HolidayModal from "./HolidayModal";
import authenticatedInstance from "../../custom-axios/axios-auth";

const HomePage = () => {
    const {selectedType, setSelectedType, zipCode, setZipCode} = useSearchContext();
    // Use the search hook
    const {handleSearch} = useSearch(zipCode, selectedType);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Navigation for redirecting to results page
    const cardTitles = selectedType === 'product' ? productTitles : serviceTitles;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoriesHolidays, setCategoriesHolidays] = useState([]);
    const [isZipValid, setIsZipValid] = useState(false);

    useEffect(() => {
        const modalClosed = localStorage.getItem("modalClosed");

        // If the modal has been closed, we don't want to show it again
        if (!modalClosed) {
            fetchCategories();
        }
    }, []);

    useEffect(() => {
        // Only open the modal if the categories are fetched and the length is greater than 0
        if (categoriesHolidays.length > 0 && !localStorage.getItem("modalClosed")) {
            setIsModalOpen(true);
        }
    }, [categoriesHolidays]);
    // Function to close the modal and save to localStorage
    const closeModal = () => {
        setIsModalOpen(false);
        localStorage.setItem("modalClosed", "true"); // Mark the modal as closed in localStorage
    };
    // Dynamically update CSS root variables based on selected product/service
    useThemeSwitcher(selectedType);


    // Handle ZIP code change and validation
    const handleZipChange = (zip) => {
        setZipCode(zip); // Update zip code in context
        const isValid = /^\d{2,5}$/.test(zip) || zip.length === 0; // Validate zip code (2-5 digits)
        setIsZipValid(isValid); // Set validity state
    };

    useEffect(() => {
        handleZipChange(zipCode); // Validate on component mount
    }, [zipCode]);
    const fetchCategories = async () => {
        try {
            // Fetch holidays (same regardless of product type)
            const holidaysResponse = await authenticatedInstance.get('/categories/holidays');
            const holidays = holidaysResponse.data;

            // Get today's date
            const today = new Date();

            // Filter holidays that are within 30 days from today
            const upcomingHolidays = holidays.filter((holiday) => {
                // Parse the date
                const holidayDate = new Date(holiday.date);
                // Calculate the difference in time and convert it to days
                const timeDifference = holidayDate.getTime() - today.getTime();
                const daysDifference = timeDifference / (1000 * 3600 * 24);

                // Return true if the holiday is within 30 days
                return daysDifference >= 0 && daysDifference <= 30;
            });

            // Extract unique category names and map them into the format { label, value }
            const uniqueCategoryHolidays = [
                ...new Map(upcomingHolidays.map((holiday) => [holiday.id, holiday])).values()
            ];
            // Update state
            setCategoriesHolidays(uniqueCategoryHolidays);

        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to load categories.');
        }
    };

    // Search handler with product/service and ZIP code

    return (
        <div>

            {/* Main Content */}
            <div className="main-section relative flex justify-center items-center h-screen">
                {/* Background Image */}
                <div className="image-background absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center z-[-1]"
                     style={{backgroundImage: `url('/images/background_homepage.jpg')`}}></div>

                <div
                    className="circle bg-[#789DBC] rounded-full w-[42em] h-[42em] flex flex-col justify-center items-center text-center absolute bottom-[-12em]">

                    {/* SVG Text inside Circle */}
                    <svg id="thehomemadeshop" viewBox="0 0 600 350" className="svg-text w-full h-auto">
                        <path id="curve" fill="transparent" d="M 5,340 A 277,277 0 0,1 605,340"></path>
                        <text width="500">
                            <textPath xlinkHref="#curve" startOffset="50%" textAnchor="middle" className="fill-current"
                                      style={{fill: 'var(--svg-text-color)'}}>
                                TheHomeMadeShop.com
                            </textPath>
                        </text>
                    </svg>
                    <h2 className="header-text text-3xl font-light" style={{color: 'var(--h2-color)'}}>Made with
                        love</h2>
                    <h3 id={'h3-header'} style={{color: 'var(--h3-color)'}}>Use your neighbours hands - Free of rush
                        and drama</h3>

                    {/* Product/Service Buttons */}
                    <div className="buttons flex gap-8 mt-6">
                        <button
                            className="button button-products"
                            style={{
                                backgroundColor: 'var(--products-btn-color)',
                                color: 'var(--products-btn-text)',
                                border: '2px solid #B23278'
                            }}
                            onClick={() => setSelectedType('product')}  // Update the selected type in context
                        >
                            Products
                        </button>
                        <button
                            className="button button-services"
                            style={{
                                backgroundColor: 'var(--services-btn-color)',
                                color: 'var(--services-btn-text)',
                                border: '2px solid #253329'
                            }}
                            onClick={() => setSelectedType('service')}  // Update the selected type in context
                        >
                            Services
                        </button>
                    </div>
                    <p className="mt-4" style={{color: 'var(--p-text-color)'}}>
                        Find local products and services by your ZIP code
                    </p>
                    {/* Zip Search Input with Autocomplete */}
                    <div className="input-container mt-4">
                        <div className="flex items-center space-x-4">
                            <Autocomplete zipCode={zipCode} setZip={setZipCode}/>
                            <button
                                onClick={handleSearch}
                                className={`px-5 py-2 rounded-md ml-2 text-clip ${!isZipValid ? 'cursor-not-allowed opacity-50' : ''}`} // Add opacity and cursor style when disabled
                                style={{
                                    backgroundColor: 'var(--search-btn-color)',
                                    color: 'var(--search-btn-text)',
                                    border: '2px solid var(--circle-border-color)',
                                    whiteSpace: 'nowrap'
                                }}
                                disabled={!isZipValid}  // Disable the button if ZIP code is invalid
                                title={isZipValid ? '' : 'Enter a valid ZIP code (2-5 digits)'}  // Tooltip on hover
                            >
                                {selectedType === 'product' ? 'Find Shops' : 'Find Services'}
                            </button>
                        </div>
                        {error && <p className="text-customRed mt-4">{error}</p>}
                    </div>

                    {/* Additional Text */}
                    <p className="mt-4" style={{color: 'var(--p-text-color)'}}>
                        Unique and high-quality products and services in TheHomeMadeShop.com next door
                    </p>

                </div>
            </div>

            {/* Cards Section */}
            <div className={"w-100"} style={{top: "-100px"}}>
                <HomePageCards cardTitles={cardTitles} selected={selectedType}/>
            </div>
            <HolidayModal isOpen={isModalOpen} onClose={closeModal} categories={categoriesHolidays}/>
        </div>
    );
};

export default HomePage;