// src/components/App/App.js
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
// import '../../css/cssfile.css'
import LoginComponent from '../Login/LoginComponent';
import RegisterComponent from '../Register/RegisterComponent';
import SellerRegistrationComponent from '../Seller/SellerRegistrationComponent';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import SubmissionDetailsComponent from '../Submission/SubmissionDetailsComponent';
import SearchResults from '../Search/SearchResults'
import LogoutComponent from '../Logout/LogoutComponent';
import AllSubmissions from "../Submission/AllSubmissions/AllSubmissions";
import NotReviewedSubmissions from "../Submission/NotReviewedSubmissions/NotReviewedSubmissions";
import DeclinedSubmissions from "../Submission/DeclinedSubmissions/DeclinedSubmissions";
import ApprovedSubmissions from "../Submission/ApprovedSubmissions/ApprovedSubmissions";
import GoogleLoginButton from "../GoogleLogin/GoogleLoginButton";
import ShopDetails from "../Shop/ShopDetails";
import UsersComponent from "../Users/UsersComponent";
import UserDetailsComponent from "../Users/UserDetailsComponent";
import CustomerProfileFormComponent from "../Profiles/Forms/CustomerProfileFormComponent";
import SellerProfileFormComponent from "../Profiles/Forms/SellerProfileFormComponent";
import AdminProfileFormComponent from "../Profiles/Forms/AdminProfileFormComponent";
import ProductDetails from "../Shop/ProductDetails";
import ChatPage from "../Chat/ChatPage";
import ConversationsPage from "../Chat/ConversationsPage";
import ChatLayout from "../Chat/ChatLayout";
import ChatApp from "../Chat/ChatApp";
import SellerOrdersPage from "../Order/SellerOrdersPage";
import CustomerOrdersPage from "../Order/CustomerOrdersPage";
import TestComponent from "../TestComponent";
import {UserProvider} from "../../contexts/UserContext";
import SellerSubmission from "../Seller/Submission/SellerSubmission";
import NotFoundPage from "../Exceptions/NotFoundPage";
import UnauthorizedPage from "../Exceptions/UnauthorizedPage";
import SettingsPage from "../Settings/SettingsPage";
import AdminHomePageManager from "../AdminHome/AdminHomePageManager";
import FeatureAccess from "../TestStripeComponent";
import SubscriptionPlans from "../Stripe/SubscriptionForm";
import ManageSubscription from "../Stripe/old/ManageSubscription";
import AppWrapper from "../Stripe/old/SubscriptionManagement";
import PaymentSuccess from "../Stripe/PaymentSuccess";
import SubscriptionPlansNoTrial from "../Stripe/old/SubscriptionPlansNoTrial";
import ConfirmAccount from "../Users/ConfirmAccount";
import ReactGA from 'react-ga4';
import SellerRegistrationWrapper from "../Seller/SellerRegistrationWrapper";
import {SubscriptionProvider} from "../../contexts/SubscriptionContext";
import ChangeSubscriptionComponent from "../Stripe/ChangeSubscriptionComponent";
import HomePage from "../Frontpage/HomePage";
import {SearchProvider} from "../../contexts/SearchContext";
import PremiumSubscription from "../Stripe/PremiumSubscription";
import StandardSubscription from "../Stripe/StandardSubscription";
import PasswordChange from "../Settings/PasswordChange";
import Layout from "../Layout/Layout";
import ResponsiveHome from "../Frontpage/ResponsiveHome";
import RegistrationSuccessPage from "../Seller/RegistrationSuccessPage";
import Calendar from "../Order/Calendar/Calendar";
import {OrderSortingProvider} from "../../contexts/OrderSortingContext";
import OrderComponent from "../Order/Calendar/Orders/OrderComponent";
import {OrderProvider} from "../../contexts/OrderContext";
import EmailChange from "../Settings/EmailChange";


//
const SellerTips = lazy(() => import("../Footer/Sellers/SellerTips"));
const Subscriptions = lazy(() => import("../Footer/Sellers/Subscriptions"));
const FAQPage = lazy(() => import("../Footer/FAQPage"));
const AboutUs = lazy(() => import("../Footer/Company/AboutUs"));
const PrivacyPolicy = lazy(() => import("../Footer/Company/PrivacyPolicy"));
const TermsOfService = lazy(() => import("../Footer/Company/TermsOfService"));
const AllProductsPage = lazy(() => import("../Footer/Customers/AllProductsPage"));
const AllServicesPage = lazy(() => import("../Footer/Customers/AllServicesPage"));
const AllShopsPage = lazy(() => import("../Footer/Customers/AllShopsPage"));
const AllSellersPage= lazy(() => import("../Footer/Customers/AllSellersPage"));
const FindYourZipCode = lazy(() => import("../Footer/Customers/FindYourZipCode"));
const FindNearbyZipCodes = lazy(() => import("../Footer/Customers/FindNearbyZipCodes"));



ReactGA.initialize('G-W157MNTWF1');

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [role, setRole] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('JWT');
        const userRole = localStorage.getItem('role'); // Assuming you store the role in localStorage
        if (token) {
            setIsLoggedIn(true);
            setRole(userRole);
        }
    }, []);

    useEffect(() => {
        ReactGA.send('pageview');  // Log initial page load
    }, []);

    const Spinner = () => (
        <div className="spinner"></div>
    );
    return (
        <UserProvider>
            <SubscriptionProvider>
                <SearchProvider>
                    <Router>
                        <Layout>
                            <Suspense fallback={<Spinner />}>

                                <Routes>
                                    {/* Public routes */}
                                    <Route path="/" element={<ResponsiveHome />} />
                                    <Route path="/results" element={<SearchResults />} />
                                    <Route path="/login" element={<LoginComponent />} />
                                    <Route path="/register" element={<RegisterComponent />} />
                                    <Route path="/shop-details/:id" element={<ShopDetails />} />
                                    <Route path="/product/:productId" element={<ProductDetails />} />
                                    <Route path="/confirm-account" element={<ConfirmAccount />} />
                                    <Route path="/not-found" element={<NotFoundPage />} />
                                    <Route path="/unauthorized" element={<UnauthorizedPage />} />
                                    <Route path="/test" element={<TestComponent />} />
                                    <Route path="/test/stripe" element={<FeatureAccess />} />
                                    <Route path="/home" element={<ResponsiveHome />} />

                                        <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                            <Route path="/orders/:orderId" element={
                                                <OrderProvider>
                                                    <OrderComponent />
                                                </OrderProvider>
                                            } />
                                        </Route>



                                    {/* Footer routes  */}
                                    {/* Route for About Us */}
                                    <Route path="/footer/about-us" element={<AboutUs />} />

                                    {/* Route for Privacy Policy */}
                                    <Route path="/footer/privacy-policy" element={<PrivacyPolicy />} />

                                    {/* Route for Terms of Service */}
                                    <Route path="/footer/terms" element={<TermsOfService />} />

                                    {/* Route for Subscriptions */}
                                    <Route path="/footer/subscriptions" element={<Subscriptions />} />

                                    {/* Route for Seller Tips */}
                                    <Route path="/footer/sellers-tips" element={<SellerTips />} />

                                    {/* Route for FAQ */}
                                    <Route path="/footer/faq" element={<FAQPage />} />

                                    {/* Routes for specific FAQ sections */}
                                    <Route path="/footer/customers-faq" element={<FAQPage />} />
                                    <Route path="/footer/sellers-faq" element={<FAQPage />} />

                                    <Route path="/footer/find-all-products" element={<AllProductsPage />} />
                                    <Route path="/footer/find-all-services" element={<AllServicesPage />} />

                                    <Route path="/footer/find-all-shops" element={<AllShopsPage />} />
                                    <Route path="/footer/all-sellers" element={<AllSellersPage />} />
                                    <Route path="/footer/find-your-zip-code" element={<FindYourZipCode />} />
                                    <Route path="/footer/find-nearby-zip-codes" element={<FindNearbyZipCodes />} />

                                        {/* Protected chat route */}

                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/chat" element={<ChatApp />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/settings" element={<SettingsPage />} />
                                    </Route>
                                    {/*<Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>*/}
                                    {/*    <Route path="/chat/:sellerId" element={<ChatPage />} />*/}
                                    {/*</Route>*/}

                                    {/* Conversations route */}
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/conversations" element={<ConversationsPage />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/submission" element={<SellerSubmission />} />
                                    </Route>

                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/form" element={<SubscriptionPlans />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/form/no-trial" element={<SubscriptionPlansNoTrial />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/cancel" element={<ManageSubscription />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/upgrade" element={<AppWrapper />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/success" element={<PaymentSuccess />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/stripe/change" element={<ChangeSubscriptionComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/seller/subscribe/standard" element={<StandardSubscription />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/seller/subscribe/premium" element={<PremiumSubscription />} />
                                    </Route>

                                    {/* Private routes for authenticated users */}
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER']} excludedRoles={['ROLE_SELLER']} />}>
                                        <Route path="/seller/register" element={<SellerRegistrationWrapper  />} />
                                    </Route>

                                    <Route path="/seller/registration-success" element={<RegistrationSuccessPage />} />

                            <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                <Route path="/settings/changepassword" element={<PasswordChange />} />
                            </Route>
                                <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                    <Route path="/settings/changeemail" element={<EmailChange />} />
                                </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin" element={<AllSubmissions />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/notReviewed" element={<NotReviewedSubmissions />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/home" element={<AdminHomePageManager />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/approved" element={<ApprovedSubmissions />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/declined" element={<DeclinedSubmissions />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/users" element={<UsersComponent />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/users/:id" element={<UserDetailsComponent />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/submission/:id" element={<SubmissionDetailsComponent />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/users/customer/:id/edit" element={<CustomerProfileFormComponent />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/users/seller/:id/edit" element={<SellerProfileFormComponent />} />
                            </Route>
                            <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                <Route path="/admin/users/admin/:id/edit" element={<AdminProfileFormComponent />} />
                            </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER', 'ROLE_SELLER', 'ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/settings/changepassword" element={<PasswordChange />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin" element={<AllSubmissions />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/notReviewed" element={<NotReviewedSubmissions />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/home" element={<AdminHomePageManager />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/approved" element={<ApprovedSubmissions />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/declined" element={<DeclinedSubmissions />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/users" element={<UsersComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/users/:id" element={<UserDetailsComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/submission/:id" element={<SubmissionDetailsComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/users/customer/:id/edit" element={<CustomerProfileFormComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/users/seller/:id/edit" element={<SellerProfileFormComponent />} />
                                    </Route>
                                    <Route element={<PrivateRoute allowedRoles={['ROLE_ADMINISTRATOR']} />}>
                                        <Route path="/admin/users/admin/:id/edit" element={<AdminProfileFormComponent />} />
                                    </Route>

                                    {/*<Route element={<PrivateRoute  allowedRoles={['ROLE_SELLER']}/>}>*/}
                                    {/*    <Route path="/seller/orders" element={<SellerOrdersPage />} />*/}
                                    {/*</Route>*/}
                                        <Route element={<PrivateRoute allowedRoles={['ROLE_SELLER']} />}>
                                            <Route
                                                path="/seller/orders"
                                                element={
                                                    <OrderSortingProvider>
                                                        <Calendar />
                                                    </OrderSortingProvider>
                                                }
                                            />
                                        </Route>


                                    <Route element={<PrivateRoute allowedRoles={['ROLE_CUSTOMER']} />}>
                                        <Route path="/customer/orders" element={<CustomerOrdersPage />} />
                                    </Route>

                                    {/* Logout route */}
                                    <Route path="/logout" element={<LogoutComponent />} />

                                    <Route path="/google" element={<GoogleLoginButton />} />
                                </Routes>
                            </Suspense>

                        </Layout>
                    </Router>

                </SearchProvider>
            </SubscriptionProvider>
        </UserProvider>
    );
}

export default App;
