import React from 'react';

const EmailConfirmationPrompt = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Email Confirmation Required
                </h2>
                <p className="text-gray-600 mb-6">
                    Please confirm your email address to proceed. Check your inbox and spam folder for the confirmation email.
                </p>
                <button
                    onClick={() => window.location.reload()}
                    className="px-4 py-2 bg-primaryServiceColor text-white rounded-md hover:bg-secondaryServiceColor transition">
                    I Have Confirmed My Email
                </button>
            </div>
        </div>
    );
};

export default EmailConfirmationPrompt;
