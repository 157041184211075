import React from 'react';
import getOrderStatusClasses from "../getOrderStatusClasses";
import { FaCheck, FaTimes, FaBan } from 'react-icons/fa';
const OrderDetails = ({ order, openStatusModal, isSeller, setNewStatus }) => {
    const handleOpenModalWithStatus = (status) => {
        setNewStatus(status); // Set the initial status for the modal
        openStatusModal(order); // Open modal with this status
    };

    return (
        <div className="bg-gray-100 p-4 rounded-lg shadow-sm mb-4">
            <div className="mb-2">
                <strong className="text-gray-700">Order ID:</strong> {order.id}
            </div>
            <div className="mb-2">
                <strong className="text-gray-700">Product:</strong>
                <a href={`${process.env.REACT_APP_FRONTEND_URL}/product/${order.productId}`}
                   className="text-blue-500 hover:underline">
                    {order.productTitle}
                </a>
            </div>
            {order.quantity > 0 && (
                <div className="mb-2">
                    <strong className="text-gray-700">Quantity:</strong> {order.quantity}
                </div>
            )}
            <div className="mb-2">
                <strong className="text-gray-700">Status:</strong>
                <div className="flex items-center space-x-2 mt-1">
                    <button
                        onClick={() => {
                            if (order.status.status === 'AWAITING_APPROVAL' || isSeller) {
                                openStatusModal(order);
                            }
                        }}
                        disabled={
                            order.status &&
                            (order.status.status === 'CANCELLED' ||
                                order.status.status === 'EXPIRED' ||
                                (!isSeller && order.status.status !== 'AWAITING_APPROVAL'))
                        }
                        className={`px-3 py-1 text-sm font-medium rounded-md ${getOrderStatusClasses(order.status ? order.status.status : 'UNKNOWN')} ${
                            order.status && (order.status.status === 'CANCELLED' || order.status.status === 'EXPIRED') ? 'opacity-60 cursor-not-allowed' : ''
                        } transition duration-200 ease-in-out hover:bg-opacity-75`}
                        title="Click to edit status"
                    >
                        {order.status ? order.status.status : 'N/A'}
                    </button>

                    {/* Render conditional buttons inline, aligned to the right of the status button */}
                    {order.status.status === 'AWAITING_APPROVAL' && (
                        <div className="flex space-x-2">
                            {isSeller ? (
                                <>
                                    <button
                                        onClick={() => handleOpenModalWithStatus('APPROVED')}
                                        className="custom-btn-success flex items-center space-x-2 border-2 border-green-500 bg-green-100 text-green-500 px-4 py-2 rounded-md hover:bg-green-200"
                                    >
                                        <FaCheck className="text-xl"/>
                                        <span>Approve</span>
                                    </button>
                                    <button
                                        onClick={() => handleOpenModalWithStatus('CANCELLED')}
                                        className="custom-btn-danger flex items-center space-x-2 border-2 border-red-500 bg-red-100 text-red-500 px-4 py-2 rounded-md hover:bg-red-200"
                                    >
                                        <FaTimes className="text-xl"/>
                                        <span>Reject</span>
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={() => handleOpenModalWithStatus('CANCELLED')}
                                    className="custom-btn-danger flex items-center space-x-2 border-2 border-orange-500 bg-orange-100 text-orange-500 px-4 py-2 rounded-md hover:bg-orange-200"
                                >
                                    <FaBan className="text-xl"/>
                                    <span>Cancel</span>
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>


            <div className="mb-2">
                <strong className="text-gray-700">Date Ordered:</strong> {new Date(order.dateOrdered + 'Z').toLocaleString()}
            </div>
            <div className="mb-2">
                <strong className="text-gray-700">Date
                    Delivery:</strong> {new Date(order.dateDelivery + 'Z').toLocaleString()}
            </div>


        </div>
    );
};

export default OrderDetails;
